import React, { useEffect } from 'react';
import {
  Breadcrumb, Button, Tabs, Tag,
} from 'antd';
import {
  NavLink, useNavigate, useParams,
} from 'react-router-dom';
import { ExclamationCircleFilled } from '@ant-design/icons';
import clsx from 'clsx';
import CompanyForm from '../../Form';
import { useCompanyContext } from '../../Form/context';
import {
  CompanyStatus,
  useCompanyDelete, useCompanySetStatus,
  useCompanyUpdate,
} from '../../../../../hooks/api/company';
import { useMessageError, useMessageSuccess } from '../../../../../hooks/common';
import Actions from '../../../../Common/Header/Actions';
import { useSimpleModal } from '../../../../Common/Modal/Simple';
import { useBlockerContext } from '../../../../../context/blockerDisabler';
import { useSearchParams } from '../../../../../hooks/useSearchParams';
import CompanyAdminForm from '../../Form/Administrator';
import { getCompanyStatusColor } from '../../index';

export function HeaderActions() {
  const { open, contextHolder } = useSimpleModal();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    form, isValid, setInitialState, companyGetById,
  } = useCompanyContext();
  const companyUpdate = useCompanyUpdate(id);
  const companyDelete = useCompanyDelete(id || 'undefined');
  const companySetStatus = useCompanySetStatus(id || '');

  const { handleIsBlockerActive } = useBlockerContext();

  useMessageError([companyUpdate, companyDelete, companySetStatus]);
  useMessageSuccess([companyUpdate], 'Changes saved successfully');
  useMessageSuccess([companyDelete], 'Company deleted successfully');
  useMessageSuccess([companySetStatus], 'Company status updated successfully');

  const handleReActivate = () => {
    open({
      icon: <ExclamationCircleFilled />,
      title: 'Make active?',
      content: 'Are you sure you want to re-activate this customer?',
      cancelText: 'Cancel',
      okText: 'Make active',
      okButtonProps: {
        danger: true,
      },
      centered: true,
      onOk: () => companySetStatus.fetch({ status: 'active' }),
    });
  };

  useEffect(() => {
    if ((!companyUpdate.error && !companyUpdate.loading && companyUpdate.data)
    || (!companySetStatus.error && !companySetStatus.loading && companySetStatus.data)) {
      navigate(`/companies/${id}`);
    }
  }, [
    companyUpdate.error, companyUpdate.loading, companyUpdate.data,
    companySetStatus.error, companySetStatus.loading, companySetStatus.data,
  ]);

  useEffect(() => {
    if (!companyDelete.error && !companyDelete.loading && companyDelete.data) {
      /** After delete disable blocker, and after navigation - reset to default state */
      handleIsBlockerActive(false)
        .then(() => {
          navigate('/companies');
        }).then(() => handleIsBlockerActive(null));
    }
  }, [companyDelete.error, companyDelete.loading, companyDelete.data]);

  return (
    <Actions>
      {companyGetById.data?.status ? (
        <Tag color={getCompanyStatusColor(companyGetById.data?.status)} style={{ width: 'auto' }}>
          {companyGetById.data?.status}
        </Tag>
      ) : null}
      {(companyGetById.data?.status && (['delete'] as CompanyStatus[])
        .includes(companyGetById.data?.status) && companyGetById.data?.role === 'crm') ? (
          <Button
            type="default"
            onClick={handleReActivate}
            loading={companySetStatus.loading}
          >
            Re-activate
          </Button>
        ) : null}

      <Button
        danger
        loading={companyDelete.loading}
        onClick={(e) => {
          e.preventDefault();

          open({
            icon: <ExclamationCircleFilled />,
            title: 'Delete company?',
            content: (
              <span>
                Are you sure you want to delete company
                {' '}
                <b>{form?.getFieldValue('companyName')}</b>
                ?
              </span>
            ),
            cancelText: 'Cancel',
            okText: 'Delete',
            okButtonProps: {
              danger: true,
            },
            onOk: () => companyDelete.fetch(),
          });
        }}
      >
        Delete
      </Button>
      <Button
        type="primary"
        disabled={!isValid}
        loading={companyUpdate.loading}
        onClick={(e) => {
          e.preventDefault();
          if (form) {
            const data = form.getFieldsValue();

            setInitialState(data);
            companyUpdate.fetch(data);
          }
        }}
      >
        Save
      </Button>

      {contextHolder}
    </Actions>
  );
}

const tabs = [
  {
    forceRender: true,
    key: 'General info',
    label: 'General info',
    children: <CompanyForm />,
  },
  {
    forceRender: true,
    key: 'Administrator',
    label: 'Administrator',
    children: <CompanyAdminForm />,
  },
];

export default function CompanyEdit(): React.ReactNode | null {
  const { id = '' } = useParams<{ id: string }>();
  const { initialState } = useCompanyContext();
  const [_, setSearchParams, params] = useSearchParams();

  return (
    <>
      <Breadcrumb
        className="transparent"
        items={[
          {
            title: <NavLink to="/companies">Customers</NavLink>,
          },
          {
            title: <NavLink to={`/companies/${id}`}>{initialState?.companyName || 'Current Customer'}</NavLink>,
          },
          {
            title: 'Edit customer',
          },
        ]}
      />

      <div
        className={clsx('form-customized-cols', 'miniTopPadding', 'content-container', 'order-form-rewrites')}
      >
        <Tabs
          key="customerTabs"
          defaultActiveKey={(params.tab as string) || 'General info'}
          activeKey={(params.tab as string) || 'General info'}
          items={tabs}
          onChange={(key) => setSearchParams({ tab: key })}
        />
      </div>
    </>
  );
}
