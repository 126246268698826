import React, { useEffect } from 'react';
import { Form, Radio } from 'antd';
import clsx from 'clsx';
import TextArea from 'antd/es/input/TextArea';
import { FormItemProps } from 'antd/es/form/FormItem';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { FormName, useOrderContextForm } from '../context';
import { FormWrapper } from '../index';
import { capitalizeFirstLetter } from '../../../../../utils';
import { ClientDataGood, ClientProductType } from '../../Adapter';
import { useAuth } from '../../../../../store/auth';
import { isRoleEnough } from '../../../../../enums/user';
import { useSimpleModal } from '../../../../Common/Modal/Simple';
import { useOrderContext } from '../../View/context';

import styles from '../index.module.scss';

const yesNoOptions = [{ value: 'true', label: 'Yes' }, { value: 'false', label: 'No' }];

const yesNoFormItemProps: FormItemProps = {
  getValueProps: (value) => (
    { value: (value)?.toString() || 'false' }
  ),
  normalize: (value) => JSON.parse(value),
};

type OrderTypeProps = FormName

export default function OrderType({ formName }: OrderTypeProps): React.ReactNode | null {
  const {
    setValidator, forms: { [formName]: form, goods: goodsForm }, triggerValidationAllForm, deliveryForm,
  } = useOrderContextForm();
  const { user } = useAuth();
  const { clientOrderData } = useOrderContext();
  const { open, contextHolder } = useSimpleModal();

  const isUserAdmin = isRoleEnough(user?.role, 'admin');

  useEffect(() => {
    setValidator(formName, () => !(
      form.getFieldsError().some(({ errors }) => errors.length)));
  }, [form]);

  const productTypeWatch: ClientProductType | '' = Form.useWatch('productType', form);
  const isTemperatureControlWatch = Form.useWatch('isTemperatureControl', form);
  const isAssistanceNeededWatch = Form.useWatch('isAssistanceNeeded', form);
  const goodsWatch: ClientDataGood[] = Form.useWatch(['goods'], goodsForm);

  const isAssistanceSelected: boolean = ['dangerous', 'both'].includes(productTypeWatch || '')
    || !!isTemperatureControlWatch || !!isAssistanceNeededWatch;

  const assistanceFieldsHidden: boolean = ['dangerous', 'both'].includes(productTypeWatch || '')
    || !!isTemperatureControlWatch;

  useEffect(() => {
    if (isAssistanceSelected) {
      deliveryForm.validateFields(['modeOfTransport', 'deliveryTerms', 'deliveryService']);
    }
  }, [isAssistanceSelected]);

  return (
    <FormWrapper
      formName={formName}
      className={clsx(styles.wrapper, styles.vertical)}
      layout="vertical"
      initialValues={isUserAdmin ? ({
        productType: 'regular', isTemperatureControl: false, isAssistanceNeeded: true, isOfferNeeded: false,
      }) : { productType: 'regular', isTemperatureControl: false, isAssistanceNeeded: false }}
    >
      {contextHolder}
      <Form.Item
        label={isUserAdmin ? 'Shipped products' : 'What type of product do you want to ship?'}
        name="productType"
        tooltip={(
          <div>
            Regular products can be shipped immediately.
            Orders with dangerous products are processed by Sephyre before shipping
          </div>
        )}
        rules={[{ required: true }]}
      >
        <Radio.Group
          onChange={(e) => {
            if (['dangerous', 'both'].includes(e.target.value || '')) {
              form.setFieldValue('isAssistanceNeeded', true);
            }

            if (e.target.value === 'regular' && goodsWatch.some((item) => item.dangerousGoods)) {
              open({
                icon: <ExclamationCircleFilled />,
                title: 'Change products to regular',
                content: (
                  <span>
                    Some of your products are marked as dangerous.
                    <br />
                    Are you sure you want to change it to regular?
                  </span>
                ),
                cancelText: 'Cancel',
                onCancel: () => {
                  form.setFieldValue(
                    'productType',
                    clientOrderData?.orderType?.productType !== 'regular'
                      ? clientOrderData?.orderType.productType : 'dangerous',
                  );
                },
                okText: 'Change to regular',
                onOk: () => {
                  const newGoods = (goodsWatch || [])
                    .map((itemG) => ({
                      ...itemG,
                      dangerousGoods: false,
                      dangerousGood: null,
                      dangerousGood_view: null,
                      packagingGroup: null,
                    }));

                  goodsForm.setFieldValue('goods', newGoods);
                },
              });
            }
          }}
        >
          {['regular', 'dangerous', 'both'].map((item) => (
            <Radio.Button key={item} value={item}>{capitalizeFirstLetter(item)}</Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label={isUserAdmin
          ? 'Temp. mode or tracking'
          : 'Does your shipment need temperature control or real-time tracking?'}
        name="isTemperatureControl"
        tooltip={(
          <div>
            Orders with special temperature mode or real-time tracking are processed by Sephyre before shipping
          </div>
        )}
        {...yesNoFormItemProps}
        rules={[{ required: true }]}

      >
        <Radio.Group onChange={(e) => {
          if (JSON.parse(e.target.value || 'false')) {
            form.setFieldValue('isAssistanceNeeded', true);
          }
        }}
        >
          {yesNoOptions.map(({ value, label }) => (
            <Radio.Button key={value} value={value}>{label}</Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label={isUserAdmin ? 'Sephyre assistance' : 'Do you need another Sephyre service or manager assistance?'}
        name="isAssistanceNeeded"
        tooltip={(
          <div>
            Consulting, support, packaging, transport or preparation of documents and other assistance
          </div>
          )}
        {...yesNoFormItemProps}
        rules={[{ required: true }]}
        style={assistanceFieldsHidden ? { display: 'none' } : undefined}
      >
        <Radio.Group>
          {yesNoOptions.map(({ value, label }) => (
            <Radio.Button key={value} value={value}>{label}</Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>

      {isAssistanceNeededWatch && !assistanceFieldsHidden ? (
        <Form.Item
          // label="Assistance notes"
          name="assistanceNote"
        >
          <TextArea
            rows={3}
            placeholder="Please indicate which services or assistance you need (optional)"
            showCount
            maxLength={600}
          />
        </Form.Item>
      ) : null}

      {isAssistanceSelected ? (
        <>
          {!isUserAdmin ? (
            <Form.Item
              label="Is your order urgent?"
              name="urgent"
              {...yesNoFormItemProps}
              rules={[{ required: true }]}
              initialValue={false}
            >
              <Radio.Group>
                {yesNoOptions.map(({ value, label }) => (
                  <Radio.Button key={value} value={value}>{label}</Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          ) : null}

          <Form.Item
            label={isUserAdmin ? 'Offer request' : 'Do you need an offer?'}
            name="isOfferNeeded"
            {...yesNoFormItemProps}
            rules={[{ required: true }]}
            initialValue={false}
          >
            <Radio.Group>
              {yesNoOptions.map(({ value, label }) => (
                <Radio.Button key={value} value={value}>{label}</Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
        </>
      ) : null}

    </FormWrapper>
  );
}
