import React, { useEffect, useMemo, useState } from 'react';
import {
  Badge, Button, Divider, DividerProps, Dropdown, Empty, MenuProps, Typography,
} from 'antd';
import clsx from 'clsx';
import {
  PlusOutlined,
} from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useOrderContext } from '../../View/context';
import { getInvoiceStatusColor } from '../../../Invoices';
import { capitalize, downloadFromAnchor } from '../../../../../utils';
import { useSimpleModal } from '../../../../Common/Modal/Simple';
import { getVisibleInvoices } from '../../View/InvoicesList';
import { Invoice, useInvoiceDownload, useOrderInvoiceSetVisible } from '../../../../../hooks/api/invoices';
import { Offer, useOfferDownload } from '../../../../../hooks/api/offers';
import PaperworkActions from './Actions';
import InvoiceDetailsContainer from '../../../Invoices/View/DetailsContainer';
import ModalFormInvoice, { findMainInvoice } from './Forms/ModalInvoice';
import InvoicesModal from '../../../Invoices/View';
import OffersModal from '../../../Offers/View';
import ModalFormDeliveryNote from './Forms/ModalDeliveryNote';
import DeliveryNoteModal, { DeliveryNoteTable } from '../DeliveryNote/View';
import { InvoiceStatus } from '../../Adapter/enums';
import { useAuth } from '../../../../../store/auth';
import { useMessageError, useMessageSuccess } from '../../../../../hooks/common';

import { getVisibleOffers } from '../../View/OffersList';
import { getOffersStatusColor } from '../../../Offers';
import { DeliveryNote, useDeliveryNoteDownload } from '../../../../../hooks/api/deliveryNotes';

import styles from './index.module.scss';
import commonStyles from '../index.module.scss';

export function DividerStyled(props: DividerProps) {
  return <Divider orientation="left" style={{ margin: '12px 0' }} className={styles.divider} {...props} />;
}

export interface SelectedItemPaperwork {
  type: ListItemType,
  id: string;
  item?: Invoice | Offer | DeliveryNote;
}

type ListItemType = 'invoice' | 'offer' | 'deliveryNote' | null;

export enum ListItemEnum {
  invoice = 'Invoice',
  offer = 'Offer',
  deliveryNote = 'Delivery Note',
}

interface ListItemProps extends React.PropsWithChildren {
  id: string;
  type: ListItemType;
  item?: Invoice | Offer | DeliveryNote;
  selectedState: SelectedItemPaperwork;
  handleSelect: (props: SelectedItemPaperwork) => void;
  extra?: React.ReactNode;
}
function ListItem({
  id, type,
  item, selectedState, handleSelect, children, extra, ...rest
}: ListItemProps) {
  return (
    <div
      className={clsx(styles.listItem, {
        [styles.active]: selectedState.type === type && (
          selectedState.id === id || selectedState.item?.number === item?.number),
      })}
      role="button"
      tabIndex={-1}
      onClick={() => handleSelect({
        type,
        id,
        item,
      })}
      {...rest}
    >
      <Button type="link" size="small" className="clip">
        {children}
      </Button>

      {extra}
    </div>
  );
}

const initialSelectedItemState: SelectedItemPaperwork = {
  type: null,
  id: '',
  item: undefined,
};

interface FormModalProps {
  open: boolean;
  id?: string;
}

function Paperwork() {
  const { id: orderId = '' } = useParams();
  const { user } = useAuth();
  const { order, clientOrderData, handleClientOrderData } = useOrderContext();
  const { contextHolder } = useSimpleModal();
  const setOrderInvoiceVisible = useOrderInvoiceSetVisible();

  const [selectedState, setSelectedState] = useState<SelectedItemPaperwork>(
    initialSelectedItemState,
  );

  const [invoiceModal, setInvoiceModal] = useState<FormModalProps>({
    open: false,
    id: undefined,
  });

  const [noteModal, setNoteModal] = useState<FormModalProps>({
    open: false,
    id: undefined,
  });

  const handleOpenInvoiceModal = (id?: string) => { setInvoiceModal({ open: true, id }); };
  const handleCloseInvoiceModal = () => { setInvoiceModal({ open: false, id: undefined }); };

  const visibleInvoices = useMemo(() => (
    getVisibleInvoices(clientOrderData?.invoices || [], user?.role)
  ), [clientOrderData?.invoices]);

  const visibleOffers = useMemo(() => (
    getVisibleOffers(clientOrderData?.offers || [], user?.role)
  ), [clientOrderData?.offers]);

  const mainInvoice = useMemo(() => (findMainInvoice(visibleInvoices)), [visibleInvoices]);

  useEffect(() => {
    /** When visible invoices updates and there no selected item - set main invoice as active */
    if ((visibleInvoices || []).length > 0 && !selectedState.id) {
      setSelectedState({ type: 'invoice', item: mainInvoice, id: mainInvoice?.id });
    }
  }, [(visibleInvoices || []).length]);

  useEffect(() => {
    /** When main invoice id got updated set it as active item */
    if (selectedState.id !== mainInvoice?.id) {
      setSelectedState({ type: 'invoice', item: mainInvoice, id: mainInvoice?.id });
    }
  }, [mainInvoice?.id]);

  const handleAddInvoice = () => {
    /** When we create order backend automatically creates invisible invoice, which is not displayed,
     * so we need to make it visible */
    if (clientOrderData?.invoices?.some((item) => item?.status === InvoiceStatus.INVISIBLE)) {
      setOrderInvoiceVisible.fetch(undefined, `${clientOrderData?.id}/set-invoice-visible`)
        .then((res) => {
          if (res?.id) {
            handleClientOrderData(res);
          }
        });

      return;
    }

    handleOpenInvoiceModal();
  };

  const items: MenuProps['items'] = [
    {
      key: 'Invoice',
      label: 'Invoice',
      onClick: handleAddInvoice,
    },
    // Offers have same layout as invoices, and they get created by button 'Send as offer'.
    {
      key: 'Note',
      label: 'Note',
      onClick: () => setNoteModal({ open: true }), // id: selectedState.id
      disabled: !!clientOrderData?.deliveryNotes?.id,
    },
  ];

  const handleEditClick = () => {
    if (selectedState.type === 'invoice') {
      handleOpenInvoiceModal(selectedState.id);
    }
    if (selectedState.type === 'offer') {
      // Offers does not have EDIT view.
    }
    if (selectedState.type === 'deliveryNote') {
      setNoteModal({ open: true, id: selectedState.id });
    }
  };

  const [previewModal, setPreviewModal] = useState<ListItemType>(null);

  const handlePreviewClick = () => {
    if (selectedState.type === 'invoice') {
      setPreviewModal('invoice');
    }
    if (selectedState.type === 'offer') {
      setPreviewModal('offer');
    }
    if (selectedState.type === 'deliveryNote') {
      setPreviewModal('deliveryNote');
    }
  };

  const downloadInvoice = useInvoiceDownload('admin');
  const downloadOffer = useOfferDownload('admin');
  const downloadDeliveryNote = useDeliveryNoteDownload(orderId);

  const handleDownloadClick = () => {
    const { id, item, type } = selectedState;

    const thenCallback = (response: ArrayBuffer | null) => {
      if (!response) return;
      downloadFromAnchor(response, `${type}_${item?.number || '-'}`, 'application/pdf');
    };

    if (type === 'invoice') {
      downloadInvoice.fetch(undefined, `${id}/download-as-pdf`).then(thenCallback);
    }
    if (type === 'offer') {
      downloadOffer.fetch(undefined, `${id}/download-as-pdf`).then(thenCallback);
    }
    if (type === 'deliveryNote') {
      downloadDeliveryNote.fetch(undefined, `${id}/download-as-pdf`).then(thenCallback);
    }
  };

  useMessageError([setOrderInvoiceVisible]);
  useMessageSuccess([setOrderInvoiceVisible], 'Success!');

  return (
    <div className={clsx(styles.paperwork)}>
      {contextHolder}
      <div className={clsx(styles.leftContent, { [styles.selected]: selectedState.id })}>
        {!selectedState.item ? (
          <Empty
            description="Start by selecting document on the right first"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ) : null}

        {selectedState.id && selectedState.type ? (
          <>
            <Typography.Paragraph className={styles.title}>
              {ListItemEnum[selectedState.type]}
              {' '}
              {selectedState.item?.number}
            </Typography.Paragraph>
            {selectedState.item?.description && selectedState.type !== 'deliveryNote' ? (
              <Typography.Paragraph>
                <b>Description: </b>
                {selectedState.item?.description}
              </Typography.Paragraph>
            ) : null}
          </>
        ) : null}
        {!selectedState.item
          ? (
            <ModalFormInvoice
              open={invoiceModal.open}
              handleClose={handleCloseInvoiceModal}
              itemId={invoiceModal.id || ''}
              afterSave={(res) => setSelectedState({ id: res.id, type: 'invoice', item: res })}
              handleSelectedItem={(props) => setSelectedState(props)}
            />
          )
          : null}
        <ModalFormInvoice
          open={invoiceModal.open}
          handleClose={handleCloseInvoiceModal}
          itemId={invoiceModal.id || ''}
          afterSave={(res) => setSelectedState({ id: res.id, type: 'invoice', item: res })}
          handleSelectedItem={(props) => setSelectedState(props)}
        />
        {!selectedState.item
          ? null
          : (
            <>
              {selectedState.type === 'invoice' ? (
                <>
                  <InvoiceDetailsContainer invoice={selectedState.item as Invoice} orderData={clientOrderData} />
                  <InvoicesModal
                    id={selectedState.id}
                    dataSource={{ ...(selectedState.item || {}), order: order?.data } as Invoice}
                    isModalOpen={previewModal === 'invoice'}
                    handleClose={() => setPreviewModal(null)}
                    afterAction={() => order.fetch()}
                    afterDelete={() => setSelectedState(initialSelectedItemState)}
                  />
                </>
              ) : null}

              {selectedState.type === 'offer' ? (
                <>
                  <InvoiceDetailsContainer
                    invoice={selectedState.item as Offer}
                    orderData={clientOrderData}
                    simplified
                  />
                  <OffersModal
                    id={selectedState.id}
                    dataSource={{
                      ...(selectedState.item || {}),
                      status: (selectedState.item as Offer)?.offerStatus,
                      order: order?.data,
                    } as Offer}
                    isModalOpen={previewModal === 'offer'}
                    handleClose={() => setPreviewModal(null)}
                    afterAction={() => order.fetch()}
                    afterDelete={() => setSelectedState(initialSelectedItemState)}
                  />
                </>
              ) : null}
            </>
          )}

        <ModalFormDeliveryNote
          open={noteModal.open}
          handleClose={() => setNoteModal({ open: false, id: undefined })}
          itemId={noteModal.id || ''}
          afterSave={(res) => setSelectedState({ id: res.id, type: 'deliveryNote', item: res })}
        />
        {selectedState.type === 'deliveryNote' ? (
          <>
            <DeliveryNoteTable orderData={clientOrderData} />
            <DeliveryNoteModal
              clearSelectedState={() => setSelectedState(initialSelectedItemState)}
              orderId={orderId}
              id={selectedState.id}
              dataSource={selectedState.item ? selectedState.item as DeliveryNote : undefined}
              isModalOpen={previewModal === 'deliveryNote'}
              handleClose={() => setPreviewModal(null)}
            />
          </>
        ) : null}

        <PaperworkActions
          selectedState={selectedState}
          handleSelectedState={setSelectedState}
          clearSelectedState={() => setSelectedState(initialSelectedItemState)}
          onAction={{
            preview: { visible: true, onClick: handlePreviewClick },
            edit: {
              visible: !!(selectedState?.type && ['invoice', 'deliveryNote'].includes(selectedState?.type)),
              onClick: handleEditClick,
            },
            download: {
              visible: true,
              onClick: handleDownloadClick,
              loading: downloadInvoice.loading || downloadOffer.loading,
            },
          }}
        />
      </div>

      <div className={clsx(styles.rightContent)}>

        <Dropdown menu={{ items }} trigger={['click']}>
          <Button
            type="default"
            icon={<PlusOutlined />}
            className={styles.addDocumentBtn}
            loading={setOrderInvoiceVisible.loading}
          >
            Add new document
          </Button>
        </Dropdown>

        {visibleInvoices.length > 0 ? (
          <div className={styles.section}>
            <DividerStyled>Invoices</DividerStyled>

            <div className={styles.list}>
              {visibleInvoices.map((invoice) => (
                <ListItem
                  key={invoice?.id}
                  id={invoice?.id}
                  item={invoice}
                  type="invoice"
                  selectedState={selectedState}
                  handleSelect={setSelectedState}
                  extra={(
                    <Badge
                      color={getInvoiceStatusColor(invoice.status)}
                      text={capitalize(invoice?.status)}
                    />
                  )}
                >
                  {`INVOICE ${invoice.number}`}
                </ListItem>
              ))}
            </div>
          </div>
        ) : null}

        {visibleOffers.length > 0 ? (
          <div className={styles.section}>
            <DividerStyled>Offers</DividerStyled>

            <div className={styles.list}>
              {visibleOffers.map((offer) => (
                <ListItem
                  key={offer.id}
                  id={offer.id}
                  item={offer}
                  type="offer"
                  selectedState={selectedState}
                  handleSelect={setSelectedState}
                  extra={(
                    <Badge
                      color={getOffersStatusColor(offer.offerStatus)}
                      text={capitalize(offer?.offerStatus)}
                    />
                  )}
                >
                  {`Offer ${offer.number}`}
                </ListItem>
              ))}
            </div>
          </div>
        ) : null}

        {clientOrderData?.deliveryNotes ? (
          <div className={styles.section}>
            <DividerStyled>Delivery Note</DividerStyled>

            <div className={styles.list}>
              {([clientOrderData.deliveryNotes]).map((note) => (
                <ListItem
                  key={note.id}
                  id={note.id}
                  item={note}
                  type="deliveryNote"
                  selectedState={selectedState}
                  handleSelect={setSelectedState}
                >
                  {`DELIVERY NOTE ${note?.number || '-'}`}
                </ListItem>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Paperwork;
