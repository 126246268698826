import dayjs from 'dayjs';
import { RequestOptionsType } from '@ant-design/pro-utils/es/typing';
import {
  DefaultFetchError,
  FetchCreate,
  FetchDelete,
  FetchGet, FetchGetId,
  FetchSuccess,
  FetchUpdate,
  PagingDataResponse,
  PagingParams,
  useFetchCreate,
  useFetchDelete,
  useFetchGet, useFetchGetId,
  useFetchUpdate,
} from '../fetch';
import { Option, SimpleOption } from '../../types';
import { GetServerData, ServerData } from '../../components/Pages/Orders/Adapter';

export type Order = GetServerData

interface OrdersGetParams extends PagingParams {
  orderByColumn?: 'id';
}

export const useOrdersGet = <DD = OrdersTableData>(
  decorateData?: (data: PagingDataResponse<Order>) => DD,
  clientView = false,
): FetchGet<
  PagingDataResponse<Order>,
  OrdersGetParams,
  DefaultFetchError,
  DD
> => useFetchGet(
    `${clientView ? 'client/' : ''}orders`,
    { autoStart: false, startStateLoading: false, decorateData },
  );

export interface OrderTable extends Omit<Order, 'createdAt'> {
  key: string;
  createdAt: dayjs.Dayjs;
  [key: string]: any;
}

export interface OrdersTableData {
  data: OrderTable[];
  total: number;
}

export const useOrdersTableGet = (clientView = false) => useOrdersGet<OrdersTableData>(
  ({ data, meta }: PagingDataResponse<Order>): OrdersTableData => ({
    data: data.map(({ createdAt, ...item }: Order): OrderTable => ({
      key: item.id,
      createdAt: dayjs(createdAt),
      ...item,
    })),
    total: meta.itemCount,
  }),
  clientView,
);

export interface OrderProformaInvoicesData {
  id: string;
  location: string;
  fileSize: number;
  originalName: string;
  mimeType: string;
  encoding: string;
}

export const useOrderProformaInvoicesGet = <DD = OrderProformaInvoicesData>(id?: string, clientView = false): FetchGet<
  OrderProformaInvoicesData,
  undefined,
  DefaultFetchError,
  DD
> => useFetchGet(
    `${clientView ? 'client/' : ''}orders/${id}/invoice`,
    { autoStart: false, startStateLoading: false },
  );

export const useOrderStatusesGet = <DD = RequestOptionsType[]>(
  decorateData: ((data: string[]) => DD) =
  (data) => data.map((name) => ({
    value: name, label: name,
  })) as DD,
  autoStart = true,
): FetchGet<
  string[],
  undefined,
  DefaultFetchError,
  DD
> => useFetchGet(
    'orders/statuses',
    { autoStart, startStateLoading: autoStart, decorateData },
  );

export const useOrderModeOfTransportsGet = <DD = RequestOptionsType[]>(
  decorateData: ((data: string[]) => DD) =
  (data) => data.map((name) => ({
    value: name, label: name,
  })) as DD,
  autoStart = true,
): FetchGet<
  string[],
  undefined,
  DefaultFetchError,
  DD
> => useFetchGet(
    'orders/modes-of-transport',
    {
      autoStart,
      startStateLoading: autoStart,
      decorateData,
      multiple: 'orders/modes-of-transport',
      cacheLifetime: 5 * 60 * 1000, // 5 minute cache,
    },
  );

export const useOrderAcceptUpdate = (id?: string): FetchUpdate<Order, DefaultFetchError, undefined> => (
  useFetchUpdate('orders/accept', id)
);

export const useOrderDeclineUpdate = (id?: string): FetchUpdate<FetchSuccess, DefaultFetchError, undefined> => (
  useFetchUpdate('orders/decline', id)
);

export const useOrderMarkMeAsManager = (id?: string): FetchCreate<Order, DefaultFetchError, undefined> => (
  useFetchCreate(`orders/${id ? `${id}/mark-me-as-manager` : ''}`)
);

export const useOrderRestoreUpdate = (id?: string): FetchUpdate<FetchSuccess, DefaultFetchError, undefined> => (
  useFetchUpdate('orders/restore', id)
);

export const useOrderSendTrackingLink = (id?: string): FetchUpdate<FetchSuccess, DefaultFetchError, undefined> => (
  useFetchUpdate(`orders/${id}/send/tracking-link`)
);

export const useOrderSendTemplateEmail = (id?: string): FetchCreate<FetchSuccess, DefaultFetchError, undefined> => (
  useFetchCreate(`orders/${id}/send-as-template`)
);

export const useOrderMarkAsDelivered = (id?: string): FetchUpdate<FetchSuccess, DefaultFetchError, undefined> => (
  useFetchUpdate(`orders/deliver/${id}`)
);

export const useOrderAccountNumbers = <DD = string[]>(): FetchGet<
  string[],
  { deliveryService: string },
  DefaultFetchError,
  DD
> => useFetchGet(
    'orders/account-numbers',
    {
      autoStart: false,
      startStateLoading: false,
    },
  );

export const useOrderAccountNumbersWithLabel = <DD = { account: string, label: string }[]>(): FetchGet<
  string[],
  { deliveryService: string }, // 'dhl' | 'fedex' | 'schenker' | 'other'
  DefaultFetchError,
  DD
> => useFetchGet(
    'orders/account-numbers-with-label',
    {
      autoStart: false,
      startStateLoading: false,
    },
  );

export const useIntegrationsShipmentCreate = (id?: string): FetchUpdate<Order, DefaultFetchError, {
  deliveryService: string;
  shipmentCreation?: string;
  accountNumber?: string;
  serviceType?: string;
  serviceName?: string;
  trackingLink?: string;
  trackingNumber?: string;
}> => (
  useFetchUpdate(`integrations/shipment/create/${id}`)
);

export const useIntegrationsShipmentCancel = (id?: string): FetchUpdate<Order, DefaultFetchError> => (
  useFetchUpdate(`integrations/shipment/cancel/${id}`)
);

const validateDate = (date: string | undefined | null) => {
  if (typeof date !== 'string') {
    return null;
  }

  const result = dayjs(date).utc();

  if (result.isValid()) {
    return result;
  }

  return null;
};

export const useOrderGetById = <DD = Order>(
  id?: string,
  decorateData?: (data: Order) => DD, /*= (data): DD => ({
    ...data,
    consignmentDate: validateDate(data.consignmentDate),
    createdAt: validateDate(data.createdAt),
    dataDate: validateDate(data.dataDate),
    pickupDate: validateDate(data.pickupDate),
  } as DD), */
  clientView = false,
): FetchGetId<
  Order,
  DefaultFetchError,
  unknown,
  DD
> => useFetchGetId(
    `${clientView ? 'client/' : ''}orders`,
    id,
    {
      autoStart: !!id,
      decorateData,
      multiple: 'orders/id',
      cacheLifetime: 1000, // 1 second cache,
    },
  );

export const useOrdersDownloadAllGet = <DD = Blob>(id: string): FetchGet<
  Blob,
  OrdersGetParams,
  DefaultFetchError,
  DD
> => useFetchGet(
    `files/order/${id}/archive`,
    {
      autoStart: false,
      startStateLoading: false,
      config: {
        responseType: 'blob',
      },
    },
  );

export type OrderCreateParams = ServerData

export const useOrderCreate = (clientView = false): FetchCreate<Order, DefaultFetchError, OrderCreateParams> => (
  useFetchCreate(`${clientView ? 'client/' : ''}orders`)
);

export const useOrderCopy = (clientView = false): FetchCreate<Order, DefaultFetchError, { id: string }> => (
  useFetchCreate(`${clientView ? 'client/' : ''}orders/copy`)
);

export const useOrderRequestAssistance = (): FetchCreate<
  FetchSuccess, DefaultFetchError, { assistanceNote: string }> => (
  useFetchCreate('orders') // '{id}/request-support'
);

export const useOrderCancelAssistance = (id?: string): FetchCreate<
  FetchSuccess, DefaultFetchError, { assistanceNote: string }> => (
  useFetchCreate(`orders/${id}`) // '{id}/cancel-request-support'
);

export const useOrderSetToBePaid = (id?: string): FetchCreate<
  FetchSuccess, DefaultFetchError, { assistanceNote: string }> => (
  useFetchCreate(`client/orders/${id}`) // '{id}/set-to-be-paid'
);

export type OrderUpdateParams = ServerData;

export const useOrderUpdate = (
  id?: string,
  clientView = false,
): FetchUpdate<Order, DefaultFetchError, OrderUpdateParams> => (
  useFetchUpdate(`${clientView ? 'client/' : ''}orders`, id)
);

export const useOrderDelete = (id?: string, clientView = false): FetchDelete<
  FetchSuccess, DefaultFetchError
> => (
  useFetchDelete(`${clientView ? 'client/' : ''}orders`, id)
);

export const useOrderGoodDelete = (id?: string): FetchDelete< // TODO check is this fetch needed for client?.
  FetchSuccess, DefaultFetchError
> => (
  useFetchDelete('orders/goods', id)
);

export type ResponseForSelect = Option

export const useTransportGetAll = <DD = ResponseForSelect[]>(
  decorateData: ((data: string[]) => DD) =
  (data) => data.map((name) => ({
    value: name, label: name,
  })) as DD,
): FetchGet<
  string[],
  undefined,
  DefaultFetchError,
  DD
> => useFetchGet(
    'orders/modes-of-transport',
    {
      autoStart: true,
      startStateLoading: true,
      decorateData,
      multiple: 'orders/modes-of-transport',
      cacheLifetime: 5 * 60 * 1000, // 5 minute cache,
    },
  );

export const useDeliveryTermsGetAll = <DD = ResponseForSelect[]>(
  decorateData: ((data: string[]) => DD) =
  (data) => data.map((name) => ({
    value: name, label: name,
  })) as DD,
): FetchGet<
  string[],
  undefined,
  DefaultFetchError,
  DD
> => useFetchGet(
    'orders/delivery-terms',
    {
      autoStart: true,
      startStateLoading: true,
      decorateData,
      multiple: 'orders/delivery-terms',
      cacheLifetime: 5 * 60 * 1000, // 5 minute cache,
    },
  );

export const useCompanyIMDGGetAll = <DD = ResponseForSelect[]>(
  decorateData: ((data: string[]) => DD) =
  (data) => data.map((value) => ({
    value, label: value,
  })) as DD,
): FetchGet<
  string[],
  undefined,
  DefaultFetchError,
  DD
> => useFetchGet(
    'orders/company-names',
    {
      autoStart: true,
      startStateLoading: true,
      decorateData,
      multiple: 'orders/company-names',
      cacheLifetime: 5 * 60 * 1000, // 5 minute cache,
    },
  );

export const useNameStatusDeclarantGetAll = <DD = ResponseForSelect[]>(
  decorateData: ((data: string[]) => DD) =
  (data) => data.map((value) => ({
    value, label: value,
  })) as DD,
): FetchGet<
  string[],
  undefined,
  DefaultFetchError,
  DD
> => useFetchGet(
    'orders/declarants',
    {
      autoStart: true,
      startStateLoading: true,
      decorateData,
      multiple: 'orders/declarants',
      cacheLifetime: 5 * 60 * 1000, // 5 minute cache,
    },
  );

export const usePlaceGetAll = <DD = ResponseForSelect[]>(
  decorateData: ((data: string[]) => DD) =
  (data) => data.map((value) => ({
    value, label: value,
  })) as DD,
): FetchGet<
  string[],
  undefined,
  DefaultFetchError,
  DD
> => useFetchGet(
    'orders/places-names',
    {
      autoStart: true,
      startStateLoading: true,
      decorateData,
      multiple: 'orders/places-names',
      cacheLifetime: 5 * 60 * 1000, // 5 minute cache,
    },
  );

export const useOrderServiceTypes = <DD = Option[]>(
  // decorateData: ((data: []) => DD) =
  // (data) => data.map((value) => ({
  //   value, label: value,
  // })) as DD,
): FetchGet<
  SimpleOption[], // string[].
  { deliveryService: string },
  DefaultFetchError,
  DD
> => useFetchGet(
    'orders/service-types',
    {
      autoStart: false,
      startStateLoading: true,
      // decorateData,
    },
  );

export type BillingCode = 'SENDER' | 'RECIPIENT' | 'THIRD_PARTY' | 'COLLECT';
export type BillingType = 'ACCOUNT' | 'CREDIT_CARD' | 'RECIPIENT_ACCOUNT' | 'THIRD_PARTY_ACCOUNT';

export const useBillingOptionsGet = <DD = ResponseForSelect[]>(
  decorateData: ((data: string[]) => DD) =
  (data) => data.map((name) => ({
    value: name, label: name.replaceAll('_', ' '),
  })) as DD,
  fetchPath = 'billing-types',
): FetchGet<
  string[],
  undefined,
  DefaultFetchError,
  DD
> => useFetchGet(
    `orders/${fetchPath}`, // orders/billing-types | orders/billing-codes
    {
      autoStart: true,
      startStateLoading: true,
      decorateData,
      multiple: `orders/${fetchPath}`,
      cacheLifetime: 5 * 60 * 1000, // 5 minute cache,
    },
  );

export interface DangerousGoods {
  unNumber: string;
  name: string;
  classificationCode: string;
  dangerousGoodId: string;
  labels: number;
  packingGroup: string;
  statusADR: string;
  statusIATA: string;
  statusIMDG: string;
}

export interface DangerousGoodsTable extends DangerousGoods {
  key: string;
}

export interface DangerousGoodsTableData {
  data: DangerousGoodsTable[];
  total: number;
}

interface DangerousGoodsGetParams extends PagingParams {
  orderByColumn?: 'id';
}

export const useDangerousGoodsGet = <DD = DangerousGoodsTableData>(
  decorateData: ((data: PagingDataResponse<DangerousGoods>) => DD) = (({ data, meta }) => ({
    data: data.map((item: DangerousGoods): DangerousGoodsTable => ({
      key: item.dangerousGoodId,
      ...item,
    })),
    total: meta.itemCount,
  }) as DD),
): FetchGet<
  PagingDataResponse<DangerousGoods>,
  DangerousGoodsGetParams,
  DefaultFetchError,
  DD
> => useFetchGet(
    'masterdata/goods/pagginate',
    { autoStart: false, startStateLoading: false, decorateData },
  );

export interface Goods {
  id: string;
  permit: string;
  permitDate: string;
  amountPerInnerPackage: string;
  currency: string;
  dangerousGoods: boolean; // string
  density: string;
  description: string;
  dualUse: string;
  englishTechName: string;
  environmentallyHazardous: string;
  exportLicense: string;
  flashPoint: string;
  gross: string;
  height: string;
  hsCode: string;
  innerPackaging: string;
  innerPackagingNetExposiveQuantity: string;
  innerPackagingNetUnitaryQuantity: string;
  innerPackagingQuantity: string;
  isEmptyUncleaned: string;
  length: string;
  massUnit: string;
  name: string;
  net: string;
  netExplosivePerPackage: string;
  netWeightOfDryIce: string;
  packagingGroup: string;
  physicalState: string;
  pureMixSolution: string;
  pureMixSolutionPercentage: string;
  quantity: string;
  realTimeMonitoring: string;
  saveProductToDatabase: string;
  segregation: string;
  specialTemperatureMode: string;
  temperatureLogger: string;
  temperatureMode: string;
  unNumber: string;
  value: string;
  volume: string;
  width: string;
  packaging: {
    name: string;
    packageId: 14;
  };
  materialAndCode: {
    code: string;
    name: string;
    packageHomologationId: number;
    packageId: number;
  }
  innerPackagingType: {
    innerPackageMaterialId: string;
    name: string;
  };
  segregationGroup: {
    name: string;
    imdgSegregationGroupId: string;
  }[];
  // exportLicenseDocument: {
  //   createdAt: string;
  //   id: string;
  //   location: string;
  //   mimeType: string;
  //   originalName: string;
  // };
  msdsDocument: {
    createdAt: string;
    id: string;
    location: string;
    mimeType: string;
    originalName: string;
  };
}

export interface GoodsTable {
  id: string;
  key: string;
  name: string;
  packagingGroup: string;
  allData: Goods;
}

export interface GoodsGetParams extends PagingParams {
  orderByColumn?: 'id';
}

export interface GoodsTableData {
  data: GoodsTable[];
  total: number;
}

export const useGoodsGet = <DD = GoodsTableData>(
  decorateData: ((data: PagingDataResponse<Goods>) => DD) = (({ data, meta }) => ({
    data: data.map((item: Goods): GoodsTable => ({
      id: item.id || 'id',
      key: item.id || 'key',
      name: item.name,
      packagingGroup: item.packagingGroup,
      allData: item,
    })),
    total: meta.itemCount,
  }) as DD),
): FetchGet<
  PagingDataResponse<Goods>,
  GoodsGetParams,
  DefaultFetchError,
  DD
> => useFetchGet(
    'goods/saved',
    { autoStart: false, startStateLoading: false, decorateData },
  );

export const useGoodsGetForCompany = <DD = GoodsTableData>(
  decorateData: ((data: PagingDataResponse<Goods>) => DD) = (({ data, meta }) => ({
    data: data.map((item: Goods): GoodsTable => ({
      id: item.id || 'id',
      key: item.id || 'key',
      name: item.name,
      packagingGroup: item.packagingGroup,
      allData: item,
    })),
    total: meta.itemCount,
  }) as DD),
): FetchGetId<
  PagingDataResponse<Goods>,
  DefaultFetchError,
  GoodsGetParams,
  DD
> => useFetchGetId(
    'goods/saved/for-company',
    '',
    { autoStart: false, startStateLoading: false, decorateData },
  );

export const useGoodDelete = (id?: string): FetchDelete<
  FetchSuccess, DefaultFetchError
> => (
  useFetchDelete('goods/unsave', id)
);

export interface KindPackageAll {
  packageId: string;
  name: string;
}

export const useKindPackageGetAll = <DD = ResponseForSelect[]>(
  decorateData: ((data: KindPackageAll[]) => DD) =
  (data) => data.map(({ packageId, name }) => ({
    value: packageId, label: name,
  })) as DD,
): FetchGet<
  KindPackageAll[],
  undefined,
  DefaultFetchError,
  DD
> => useFetchGet(
    'masterdata/packages',
    {
      autoStart: true,
      startStateLoading: true,
      decorateData,
      multiple: 'masterdata/packages',
      cacheLifetime: 5 * 60 * 1000, // 5 minute cache,
    },
  );

export interface MaterialCodeAll {
  packageHomologationId: string;
  name: string;
  code: string;
}

export const useMaterialCodeGetAll = <DD = ResponseForSelect[]>(
  packageId: string | undefined = undefined,
  decorateData: ((data: MaterialCodeAll[]) => DD) =
  (data) => data.map(({
    packageHomologationId,
    name,
    code,
  }) => ({
    value: packageHomologationId, label: `${name} - ${code}`,
  })) as DD,
): FetchGet<
  MaterialCodeAll[],
  { packageId: string | undefined },
  DefaultFetchError,
  DD
> => useFetchGet(
    'masterdata/packagehomologation',
    {
      autoStart: true,
      startStateLoading: true,
      decorateData,
      multiple: 'masterdata/packagehomologation',
      cacheLifetime: 5 * 60 * 1000, // 5 minute cache,
      params: {
        packageId,
      },
    },
  );

export const usePhysicalStateGetAll = <DD = ResponseForSelect[]>(
  decorateData: ((data: string[]) => DD) =
  (data) => data.map((name) => ({
    value: name, label: name,
  })) as DD,
): FetchGet<
  string[],
  undefined,
  DefaultFetchError,
  DD
> => useFetchGet(
    'goods/physical-states',
    {
      autoStart: true,
      startStateLoading: true,
      decorateData,
      multiple: 'goods/physical-states',
      cacheLifetime: 5 * 60 * 1000, // 5 minute cache,
    },
  );

export const useSpecialTemperatureModeGetAll = <DD = ResponseForSelect[]>(
  decorateData: ((data: string[]) => DD) =
  (data) => data.map((name) => ({
    value: name, label: name,
  })) as DD,
): FetchGet<
  string[],
  undefined,
  DefaultFetchError,
  DD
> => useFetchGet(
    'goods/temperature-modes',
    {
      autoStart: true,
      startStateLoading: true,
      decorateData,
      multiple: 'goods/temperature-modes',
      cacheLifetime: 5 * 60 * 1000, // 5 minute cache,
    },
  );

export const useDeliveryServiceGetAll = <DD = ResponseForSelect[]>(
  decorateData: ((data: string[]) => DD) =
  (data) => data.map((name) => ({
    value: name, label: name,
  })) as DD,
  autoStart = true,
): FetchGet<
  string[],
  undefined,
  DefaultFetchError,
  DD
> => useFetchGet(
    'orders/delivery-services',
    {
      autoStart,
      startStateLoading: autoStart,
      decorateData,
      multiple: 'orders/delivery-services',
      cacheLifetime: 5 * 60 * 1000, // 5 minute cache,
    },
  );

export interface SpecialAccountAll {
  id: string;
  name: string;
}

export const useAccountGetAll = <DD = ResponseForSelect[]>(
  decorateData: ((data: SpecialAccountAll[]) => DD) =
  (data) => data.map(({ id, name }) => ({
    value: id, label: name,
  })) as DD,
): FetchGet<
  SpecialAccountAll[],
  undefined,
  DefaultFetchError,
  DD
> => useFetchGet(
    'account/all',
    {
      autoStart: true,
      startStateLoading: true,
      decorateData,
      multiple: 'shipment-creation/all',
      cacheLifetime: 5 * 60 * 1000, // 5 minute cache,
    },
  );

export interface SpecialServiceTypeAll {
  id: string;
  name: string;
}

export const useServiceTypeGetAll = <DD = ResponseForSelect[]>(
  decorateData: ((data: SpecialServiceTypeAll[]) => DD) =
  (data) => data.map(({ id, name }) => ({
    value: id, label: name,
  })) as DD,
): FetchGet<
  SpecialServiceTypeAll[],
  undefined,
  DefaultFetchError,
  DD
> => useFetchGet(
    'account/all',
    {
      autoStart: true,
      startStateLoading: true,
      decorateData,
      multiple: 'service-type/all',
      cacheLifetime: 5 * 60 * 1000, // 5 minute cache,
    },
  );

export interface SegregationGroupAll {
  imdgSegregationGroupId: string;
  name: string;
}

export const useSegregationGroupGetAll = <DD = ResponseForSelect[]>(
  decorateData: ((data: SegregationGroupAll[]) => DD) =
  (data) => data.map(({ imdgSegregationGroupId, name }) => ({
    value: imdgSegregationGroupId, label: name,
  })) as DD,
): FetchGet<
  SegregationGroupAll[],
  undefined,
  DefaultFetchError,
  DD
> => useFetchGet(
    'masterdata/imdgSegregationGroups',
    {
      autoStart: true,
      startStateLoading: true,
      decorateData,
      multiple: 'masterdata/imdgSegregationGroups',
      cacheLifetime: 5 * 60 * 1000, // 5 minute cache,
    },
  );

export interface InnerPackagingTypeAll {
  name: string,
  innerPackageMaterialId: number
}

export const useInnerPackagingTypeGetAll = <DD = ResponseForSelect[]>(
  decorateData: ((data: InnerPackagingTypeAll[]) => DD) =
  (data) => data.map(({ innerPackageMaterialId, name }) => ({
    value: innerPackageMaterialId, label: name,
  })) as DD,
): FetchGet<
  InnerPackagingTypeAll[],
  undefined,
  DefaultFetchError,
  DD
> => useFetchGet(
    'masterdata/innerPackageMaterial',
    {
      autoStart: true,
      startStateLoading: true,
      decorateData,
      multiple: 'masterdata/innerPackageMaterial',
      cacheLifetime: 5 * 60 * 1000, // 5 minute cache,
    },
  );

export interface OrdersTransportDocumentsResponseError {
  success: boolean;
  httpStatusCode: number;
  message: string;
  messageType: string;
  parameter: string;
}

export interface OrdersTransportDocument extends File {
  id: string;
  location: string;
  fileSize: number;
  originalName: string;
  mimeType: string;
  encoding: string;
}

export type OrdersTransportDocumentsResponse = OrdersTransportDocument[];

export const useOrdersTransportDocumentsGet = <DD = OrdersTransportDocumentsResponse>(
  id?: string,
): FetchGet<
  OrdersTransportDocumentsResponse,
  undefined,
  OrdersTransportDocumentsResponseError,
  DD
> => useFetchGet(
    `integrations/transport-documents/${id}`,
    { autoStart: false, startStateLoading: false },
  );

export interface HSCodeAll {
  id: string;
  cn024: string;
  su: string;
  description: string;
  createdAt: string;
}

export const useHSCodeGetAll = <DD = ResponseForSelect[]>(
  decorateData: ((data: HSCodeAll[]) => DD) =
  (data) => data.map(({ cn024, su, description }) => ({
    value: cn024,
    label: `${cn024} ${su || ''} ${description.trim() ? ` - ${description}` : ''}`
      .replace(' -  - ', ' - '),
  })) as DD,
): FetchGet<
  HSCodeAll[],
  undefined,
  DefaultFetchError,
  DD
> => useFetchGet(
    'commodity/all',
    {
      autoStart: true,
      startStateLoading: true,
      decorateData,
      multiple: 'commodity/all',
      cacheLifetime: 30 * 60 * 1000, // 30 minute cache,
    },
  );

interface AirportData {
  icao: string;
  iata: string;
  name: string;
  city: string;
  state: string;
  country: string;
  tz: string;
}

interface AirportGetParams extends PagingParams {
  search?: string
}

export const useAirportSelectGet = <DD = ResponseForSelect[]>(
  decorateData: ((data: PagingDataResponse<AirportData>) => DD) =
  ({ data }) => data?.map(({
    iata, country, city, name,
  }) => ({
    value: iata,
    label: `${country} ${city} ${name}`,
  })) as DD,
): FetchGet<
  PagingDataResponse<AirportData>,
  AirportGetParams,
  DefaultFetchError,
  DD
> => useFetchGet(
    'masterdata/airports/pagginate',
    { autoStart: false, startStateLoading: false, decorateData },
  );

interface PortData {
  code: string;
  imdg: string;
  name: string;
  city: string;
  country: string;
  tz: string;
}

interface PortGetParams extends PagingParams {
  search?: string
}

export const usePortSelectGet = <DD = ResponseForSelect[]>(
  decorateData: ((data: PagingDataResponse<PortData>) => DD) =
  ({ data }) => data?.map(({
    country, city, name, imdg,
  }) => ({
    value: imdg,
    label: `${country} ${city} ${name}`,
  })) as DD,
): FetchGet<
  PagingDataResponse<PortData>,
  PortGetParams,
  DefaultFetchError,
  DD
> => useFetchGet(
    'masterdata/ports/pagginate',
    { autoStart: false, startStateLoading: false, decorateData },
  );
