import {
  DefaultFetchError,
  FetchCreate, FetchDelete,
  FetchGet,
  FetchGetId,
  FetchSuccess,
  FetchUpdate,
  PagingDataResponse,
  PagingParams,
  useFetchCreate, useFetchDelete,
  useFetchGet,
  useFetchGetId, useFetchUpdate,
} from '../fetch';
import { ResponseForSelect } from './order';
import { User } from '../../store/auth';

export type CompanyStatus = 'new' | 'active' | 'delete';

export enum CompanyStatusEnum {
  new = 'New',
  active = 'Active',
  'delete' = 'Pending Deletion',
}

export enum CompanyStatusValue {
  ACTIVE = 'active',
  NEW = 'new',
  // BLOCKED = 'blocked',
}

export interface Company extends CompanyCreateParams {
  id: string;
  status: CompanyStatus;
  createdAt: string;
  admin?: User;
}

interface CompaniesGetParams extends PagingParams {
  orderByColumn?: 'name';
}

export const useCompaniesGet = <DD = CompaniesTableData>(
  decorateData?: (data: PagingDataResponse<Company>) => DD,
): FetchGet<
  PagingDataResponse<Company>,
  CompaniesGetParams,
  DefaultFetchError,
  DD
> => useFetchGet(
    'companies',
    { autoStart: false, startStateLoading: false, decorateData },
  );

export interface CompaniesAll {
  id: string;
  companyName: string;
}

export const useCompaniesGetAll = <DD = ResponseForSelect[]>(
  decorateData: ((data: CompaniesAll[]) => DD) =
  (data) => data.map(({ id, companyName }) => ({
    value: id, label: companyName,
  })) as DD,
): FetchGet<
  CompaniesAll[],
  CompaniesGetParams,
  DefaultFetchError,
  DD
> => useFetchGet(
    'companies/plain',
    {
      autoStart: true,
      startStateLoading: true,
      decorateData,
      multiple: 'companies/all',
      cacheLifetime: 60000,
    },
  );
export const useCompaniesAllForCustomer = <DD = Company[]>(
): FetchGet<
      Company[],
      CompaniesGetParams,
      DefaultFetchError,
      DD
    > => useFetchGet(
    'companies/plain',
    {
      autoStart: true,
      startStateLoading: true,
      multiple: 'companies/all',
      cacheLifetime: 60000,
    },
  );

export interface CompanyTable extends Company {
  key: string;
}

export interface CompaniesTableData {
  data: CompanyTable[];
  total: number;
}

export const useCompaniesTableGet = () => useCompaniesGet<CompaniesTableData>(
  ({ data, meta }: PagingDataResponse<Company>): CompaniesTableData => ({
    data: data.map((item: Company): CompanyTable => ({
      key: item.id,
      ...item,
    })),
    total: meta.itemCount,
  }),
);

export const useCompanyGetById = <DD = Company>(
  id?: string,
  decorateData?: (data: Company) => DD,
): FetchGetId<
  Company,
  DefaultFetchError,
  unknown,
  DD
> => useFetchGetId(
    'companies',
    id,
    {
      autoStart: !!id,
      decorateData,
    },
  );

export interface CompanyCreateParams {
  contactName: string;
  companyName: string;
  phone: string;
  email: string;
  emailForInvoices?: string;
  country: string;
  address1: string;
  address2: string;
  postalCode: string;
  city: string;
  state: string;
  tinNumber: string;
  registerNumber: string;
  notes?: string;
  //
  role: CompanyRole;
  participantType: 'one' | 'two';
  paymentDays?: number; // Prepayment default = 0. Other options are post-payment for 15, 30, 45, 90 days.
  client?: any | User; // TODO upd interface
}

export enum CompanyRoleEnum {
  form = 'Form',
  crm = 'Portal',
  // blocked = 'Blocked',
}

export type CompanyRole = 'form' | 'crm'; // | 'blocked';

export enum CompanyPaymentTypeEnum {
  p0 = 'Pre-payment',
  p15 = 'Post-payment 15 days',
  p30 = 'Post-payment 30 days',
  p45 = 'Post-payment 45 days',
  p90 = 'Post-payment 90 days',
}

export const useCompanyCreate = (rolePath?: 'client'): FetchCreate<
  Company, DefaultFetchError, CompanyCreateParams> => (
  useFetchCreate(rolePath ? `${rolePath}/company` : 'companies')
);

export type CompanyUpdateParams = Company;

export const useCompanyUpdate = (id?: string, clientView = false): FetchUpdate<
  Company, DefaultFetchError, CompanyUpdateParams> => (
  useFetchUpdate(clientView ? 'client/company' : 'companies', id)
);

export const useCompanyDelete = (id?: string, clientView = false): FetchDelete<
  FetchSuccess, DefaultFetchError
> => (
  useFetchDelete(clientView ? 'client/company' : 'companies', id)
);

export const useCompanyInvite = (id: string): FetchCreate<
  Company, DefaultFetchError, CompanyCreateParams> => (
  useFetchCreate(`companies/${id}/send-invitation`)
);

export const useCompanySetStatus = (id?: string): FetchCreate<
  Company, DefaultFetchError, { status: CompanyStatus }> => (
  useFetchCreate(`companies${id ? `/${id}/set-status` : ''}`)
);

export const useParticipantTypeGet = (): FetchGet<string[]> => useFetchGet('companies/participant-types');

/** Client: */
export const useCompanyClientGet = <DD = Company>(
  decorateData?: (data: Company) => DD,
): FetchGetId<
  Company,
  DefaultFetchError,
  unknown,
  DD
> => useFetchGetId(
    'client/company',
    '',
    {
      autoStart: false,
      decorateData,
    },
  );
