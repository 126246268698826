import React, { useEffect, useState } from 'react';
import {
  Breadcrumb, Button, Spin, Tabs, Tag, Typography,
} from 'antd';
import {
  NavLink, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import dayjs from 'dayjs';
import { ExclamationCircleFilled } from '@ant-design/icons';
import Orders from '../../Orders';
import {
  CompanyRoleEnum, CompanyStatus,
  useCompanyDelete,
  useCompanyInvite, useCompanySetStatus,
} from '../../../../hooks/api/company';
import { useMessageError, useMessageSuccess } from '../../../../hooks/common';
import Actions from '../../../Common/Header/Actions';
import DeleteConfirmModal from '../../../Common/Modal/DeleteConfirm';
import OffersContent from '../../Offers';
import InvoicesContent from '../../Invoices';
import AddressBookContent from '../../AddressBook';
import { useCompanyContext } from '../Form/context';
import { getCompanyStatusColor } from '../index';
import { useSimpleModal } from '../../../Common/Modal/Simple';

import styles from './index.module.scss';

export function HeaderActions() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { open, contextHolder } = useSimpleModal();
  const companyDelete = useCompanyDelete(id || 'undefined');
  const companyInvite = useCompanyInvite(id || '');
  const companySetStatus = useCompanySetStatus(id || '');
  const [modalOpen, setModalOpen] = useState(false);
  const { companyGetById } = useCompanyContext();

  useMessageError([companyDelete, companyInvite, companySetStatus]);
  useMessageSuccess([companyDelete], 'Customer deleted successfully');
  useMessageSuccess([companyInvite], 'Customer invited successfully');
  useMessageSuccess([companySetStatus], 'Company status updated successfully');

  useEffect(() => {
    if (!companyDelete.error && !companyDelete.loading && companyDelete.data) {
      navigate('../companies');
    }
  }, [
    companyDelete.error, companyDelete.loading, companyDelete.data,
  ]);

  const handleReActivate = () => {
    open({
      icon: <ExclamationCircleFilled />,
      title: 'Make active?',
      content: 'Are you sure you want to re-activate this customer?',
      cancelText: 'Cancel',
      okText: 'Make active',
      okButtonProps: {
        danger: true,
      },
      centered: true,
      onOk: () => companySetStatus.fetch({ status: 'active' })
        .then((res) => {
          if (res?.id) {
            companyGetById.fetch(undefined, id);
          }
        }),
    });
  };

  return (
    <Actions>
      {contextHolder}
      {(companyGetById.data?.status && (['delete'] as CompanyStatus[])
        .includes(companyGetById.data?.status) && companyGetById.data?.role === 'crm') ? (
          <Button
            type="default"
            onClick={handleReActivate}
            loading={companySetStatus.loading}
          >
            Re-activate
          </Button>
        ) : null}

      <Button
        type="primary"
        onClick={(e) => {
          e.preventDefault();

          navigate('edit');
        }}
      >
        Edit
      </Button>
      {(companyGetById.data?.status && (['new', 'active'] as CompanyStatus[])
        .includes(companyGetById.data?.status) && companyGetById.data?.role === 'form') ? (
          <Button type="default" onClick={() => companyInvite.fetch()} loading={companyInvite.loading}>
            Send invitation
          </Button>
        ) : null}

      <Button
        danger
        loading={companyDelete.loading}
        onClick={(e) => {
          e.preventDefault();
          setModalOpen(true);
        }}
      >
        Delete
      </Button>
      <DeleteConfirmModal
        open={modalOpen}
        handleOpen={setModalOpen}
        fetchHook={companyDelete}
        id={id || ''}
        title="Delete customer?"
        description="All data associated with this account will be permanently deleted. This action cannot be undone."
      />
    </Actions>
  );
}

export default function CompanyView(): React.ReactNode | null {
  const { companyGetById } = useCompanyContext();
  const { id } = useParams();

  useEffect(() => {
    companyGetById.fetch(undefined, id);
  }, [id]);

  const {
    companyName,
    contactName,
    phone,
    email,
    country,
    address1,
    address2,
    postalCode,
    city,
    state,
    tinNumber,
    registerNumber,
    notes,
    status = 'active',
    createdAt,
    role,
  } = companyGetById.data || {};

  const [_, setSearchParams] = useSearchParams();

  const tabs = [
    {
      key: 'Orders',
      label: 'Orders',
      children: <Orders companyId={id} />,
    },
    {
      key: 'Offers',
      label: 'Offers',
      children: <OffersContent companyId={id} />,
    },
    {
      key: 'Invoices',
      label: 'Invoices',
      children: <InvoicesContent companyId={id} />,
    },
    {
      key: 'Addresses',
      label: 'Addresses',
      children: <AddressBookContent companyId={id} />,
    },
  ];

  return (
    <>
      <Breadcrumb
        className="transparent"
        items={[
          {
            title: <NavLink to="/companies">Customers</NavLink>,
          },
          {
            title: 'View customer',
          },
        ]}
      />

      <div className={styles.company}>
        <div className={styles.info}>
          <div>
            <h4>
              Customer
            </h4>
            {companyName ? (
              <div>{companyName}</div>
            ) : null}

            <h4>
              Contact
            </h4>
            {companyName ? (
              <div>{companyName}</div>
            ) : null}
            {contactName ? (
              <div>{contactName}</div>
            ) : null}
            {phone ? (
              <a href={`tel:${phone}`}>{phone}</a>
            ) : null}
            {email ? (
              <a href={`mailto:${email}`}>{email}</a>
            ) : null}
          </div>
          <div>
            <h4>
              Address
            </h4>
            {address1 || address2 ? (
              <div>{[address1, address2].join(', ')}</div>
            ) : null}
            {city ? (
              <div>{city}</div>
            ) : null}
            {postalCode || state ? (
              <div>
                {state}
                {' '}
                {postalCode}
              </div>
            ) : null}
            {country ? (
              <div>{country}</div>
            ) : null}
          </div>
          <div>
            <h4>
              TIN number
            </h4>
            {tinNumber ? (
              <div>{tinNumber}</div>
            ) : null}
            <h4>
              Register number
            </h4>
            {registerNumber ? (
              <div>{registerNumber}</div>
            ) : null}

            <h4>
              Notes
            </h4>
            {notes ? (
              // <div className="truncate" title={notes}>{notes}</div>
              <Typography.Paragraph
                ellipsis={{
                  rows: 3,
                  expandable: 'collapsible',
                }}
              >
                {notes}
              </Typography.Paragraph>
            ) : null}
          </div>
          <div>
            <h4>
              Date added
            </h4>
            {createdAt ? (
              <div>
                {dayjs(createdAt).format('DD/MM/YYYY HH:mm')}
              </div>
            ) : null}
            <h4>
              Access
            </h4>
            {role ? (
              <div>{CompanyRoleEnum[role]}</div>
            ) : null}
            <br />
            {status ? (
              <div>
                <Tag color={getCompanyStatusColor(status)}>
                  {status}
                </Tag>
              </div>
            ) : null}
          </div>

          {companyGetById.loading ? (
            <div className={styles.spin}>
              <Spin />
            </div>
          ) : null}
        </div>

        <Tabs
          key="customer-profile-tabs"
          defaultActiveKey="Orders"
          items={tabs}
          tabBarStyle={{ margin: '0 24px 16px' }}
          onChange={() => setSearchParams({})}
        />
      </div>
    </>
  );
}
