import React, {
  createContext, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { App, Form, FormInstance } from 'antd';
import { AnyObject } from '@triare/auth-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { MessageType } from 'antd/es/message/interface';
import useRouteBlocker from '../../../Common/RouteBlocker';
import { getOnlyValueRecursive } from '../../../../utils';
import {
  Goods,
  Order,
  OrderCreateParams,
  OrderProformaInvoicesData,
  OrdersTransportDocumentsResponse,
  OrdersTransportDocumentsResponseError,
  OrderUpdateParams,
  useOrderCreate,
  useOrderProformaInvoicesGet,
  useOrdersTransportDocumentsGet,
  useOrderUpdate,
} from '../../../../hooks/api/order';
import { useMessageError, useMessageModalError, useMessageSuccess } from '../../../../hooks/common';
import {
  DefaultFetchError, FetchCreate, FetchGet, FetchUpdate,
} from '../../../../hooks/fetch';
import {
  ClientData, ClientDataDelivery, ClientDataPackages, getServerData,
} from '../Adapter';
import {
  defaultDeliveryTerms, defaultDeliveryTermsCodes, DeliveryService, ModeOfTransport,
} from '../Adapter/enums';
import { useAuth, UserRole } from '../../../../store/auth';
import { SimpleProviderProps } from '../../../../types';
import { orderTabs, tabsFormsEnum } from './index';
import { useOrderContext } from '../View/context';

interface CustomValidationField {
  field: any; // boolean?
  name: string;
  roles?: (UserRole | '')[];
}

interface ErrorFormat {
  tab: string;
  list: string[];
}

type IsSimplifiedFormType = boolean | ValidationSectionNames[];
type ValidationSectionNames = 'Products' | 'Packages' | 'Delivery';

function useValidForm(): (data: ClientData | AnyObject, isSimplifiedValidation?: IsSimplifiedFormType) => boolean {
  const refCloseMessage = useRef<MessageType | undefined>();
  const { message } = App.useApp();
  const { user } = useAuth();
  const onClose = useCallback((e: Event) => {
    if ((e.target as HTMLElement)?.closest('.anticon-close-circle')
      && (e.target as HTMLElement)?.closest('.ant-message-notice-error')
      && refCloseMessage.current
    ) {
      refCloseMessage.current();
      refCloseMessage.current = undefined;
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', onClose, false);

    return () => document.removeEventListener('click', onClose);
  }, []);

  return useCallback((data: ClientData | AnyObject, isSimplifiedValidation: IsSimplifiedFormType = false): boolean => {
    const listError = [];

    /** Helper to check if section validation should be skipped */
    const shouldSkipSection = (sectionName: ValidationSectionNames): boolean => {
      if (isSimplifiedValidation === true) {
        return true;
      }
      if (Array.isArray(isSimplifiedValidation)) {
        return isSimplifiedValidation.includes(sectionName); // Skip only sections in the array
      }

      return false;
    };

    /** Validation helper. Checks for required fields. */
    const validateFields = (
      fields: CustomValidationField[],
      list: string[],
    ) => {
      fields.forEach(({ field, name, roles }) => {
        /** If no roles specified, validate for all users; otherwise, validate for specific roles */
        if ((!roles || roles.includes(user?.role || '')) && !field) {
          list.push(name);
        }
      });
    };

    /** Function to validate simple sections (without lists, like 'goods') */
    const validateSection = (
      sectionName: string,
      validations: CustomValidationField[],
    ) => {
      const section: ErrorFormat = { tab: sectionName, list: [] };

      validateFields(validations, section.list);

      /** If errors found after field validation, push to listError */
      if (section.list.length > 0) {
        listError.push(section);
      }
    };

    const delivery: ErrorFormat = {
      tab: 'Delivery',
      list: [],
    };

    if (data.delivery) {
      const {
        shipmentCreation,
        deliveryService,
        serviceName,
        trackingNumber,
        trackingLink,
        serviceType,
        accountNumber,
        portDischarge,
        portLoading,
        departureAirport,
        destinationAirport,
        // Customer fields
        pickupDate,
        pickupTime,
        deliveryTerms,
        billingNumber,
        insurance,
        insuranceValue,
        // insuranceCurrency,
        emergencyPhoneNumber,
      }: ClientDataDelivery = data.delivery;

      const modeOfTransport = data.delivery.modeOfTransport || data.general.modeOfTransport;

      if (deliveryService) {
        // eslint-disable-next-line default-case
        switch (deliveryService) {
          case DeliveryService.OTHER:
            if (!serviceName) { delivery.list.push('serviceName'); }
            if (!trackingNumber) { delivery.list.push('trackingNumber'); }
            if (!trackingLink) { delivery.list.push('trackingLink'); }
            break;

          case DeliveryService.FEDEX:
            if (!shipmentCreation) { delivery.list.push('shipmentCreation'); }
            if (shipmentCreation === 'Manually') {
              if (!trackingNumber) { delivery.list.push('trackingNumber'); }
            } else if (!serviceType) { delivery.list.push('serviceType'); }
            break;

          case DeliveryService.DHL:
            if (!shipmentCreation) { delivery.list.push('shipmentCreation'); }
            if (!accountNumber) { delivery.list.push('accountNumber'); }
            if (shipmentCreation === 'Manually') {
              if (!trackingNumber) { delivery.list.push('trackingNumber'); }
            } else if (!serviceType) { delivery.list.push('serviceType'); }
            break;

          case DeliveryService.SCHENKER:
            if (!shipmentCreation) { delivery.list.push('shipmentCreation'); }
            if (!serviceType) { delivery.list.push('serviceType'); }
            if (shipmentCreation === 'Manually' && !trackingNumber) { delivery.list.push('trackingNumber'); }

            // This part was outside of SCHENKER but looks like it should be here only for SCHENKER
            // eslint-disable-next-line default-case
            switch (modeOfTransport) {
              case ModeOfTransport.IMDG:
                if (!portDischarge) { delivery.list.push('portDischarge'); }
                if (!portLoading) { delivery.list.push('portLoading'); }
                break;

              case ModeOfTransport.IATA:
                if (!departureAirport) { delivery.list.push('departureAirport'); }
                if (!destinationAirport) { delivery.list.push('destinationAirport'); }
                break;
            }

            break;
        }
      }

      if (!data.orderType?.isAssistanceNeeded) {
        const deliveryGeneralValidations: CustomValidationField[] = [
          { field: modeOfTransport, name: 'Mode of transport', roles: ['user'] },
          // { field: pickupDate, name: 'Pickup date', roles: ['user'] },
          // { field: pickupTime, name: 'Pickup time', roles: ['user'] },
          { field: deliveryTerms, name: 'Delivery terms', roles: ['user'] },
          // { field: billingNumber, name: 'Billing account number', roles: ['user'] },
          // { field: insurance, name: 'Transport insurance', roles: ['user'] },
          // { field: insurance && insuranceValue, name: 'Transport insurance value', roles: ['user'] },
          // { field: emergencyPhoneNumber, name: '24h Emergency number', roles: ['user'] },
          { field: deliveryService, name: 'Delivery service', roles: ['user'] },
        ];

        validateFields(deliveryGeneralValidations, delivery.list);
      }

      /** If user selected delivery terms and service is dhl - disallow for him to edit other fields */
      if (deliveryTerms) {
        validateFields([
          {
            /** For dhl and fedex we cant enter custom delivery terms. */
            field: ['dhl', 'fedex', 'schenker'].includes(deliveryService || '')
              ? defaultDeliveryTermsCodes.includes(deliveryTerms?.split(' ')?.[0])
              : true,
            name: `Delivery Terms > The delivery term is not compatible with shipments via ${
              deliveryService?.toUpperCase()
            }. Please ensure the delivery term begins with one of the following values: ${
              defaultDeliveryTermsCodes.join(', ')}`,
            roles: ['user'],
          },
        ], delivery.list);
      }

      /** When pickup date/time required:
       * If pickupDate selected - pickupTime required
       * If deliveryService === 'schenker' - date/time required
       * */
      // if (pickupDate ? (pickupTime.includes(null) || data.general.pickupTime === null) : false) {
      //   delivery.list.push('Pickup time');
      // }
      validateFields([
        { field: deliveryService === 'schenker' ? pickupDate : true, name: 'Pickup date', roles: ['user'] },
        {
          // field: !(pickupTime?.includes(null) || data.general?.pickupTime === null), // If value not null
          field: !(pickupDate ? (pickupTime?.includes(null) || data.general.pickupTime === null) : false),
          name: 'Pickup time',
          roles: ['user'],
        },
      ], delivery.list);
    }

    // If validation not simplified - validate delivery section
    if (!shouldSkipSection('Delivery') && delivery.list.length > 0) {
      listError.push(delivery);
    }

    const generalValidations: CustomValidationField[] = [
      { field: data.general?.company, name: 'Company', roles: ['admin', 'root'] },
      {
        field: data.delivery?.deliveryService === 'schenker' ? data.general?.pickupDate : true,
        name: 'Pickup date',
        roles: ['admin', 'root'],
      },
      {
        // field: data.general?.pickupDate ? data.general?.pickupTime : true,
        field: data.general?.pickupDate
          ? !data.general?.pickupTime?.includes(null) && data.general?.pickupTime !== null
          : true,
        name: 'Pickup time',
        roles: ['admin', 'root'],
      },
      {
        /** For dhl and fedex we cant enter custom delivery terms. */
        field: ['dhl', 'fedex', 'schenker'].includes(data.delivery?.deliveryService)
          ? defaultDeliveryTermsCodes.includes(data.general?.deliveryTerms?.split(' ')?.[0])
          : true,
        name: `Delivery Terms > The delivery term is not compatible with shipments via ${
          data.delivery?.deliveryService?.toUpperCase()
        }. Please ensure the delivery term begins with one of the following values: ${
          defaultDeliveryTermsCodes.join(', ')}`,
        roles: ['admin', 'root'],
      },
    ];

    // Shipper validations
    const shipperValidations: CustomValidationField[] = [
      { field: data.shipper?.company, name: 'Company' },
      { field: data.shipper?.contactName, name: 'Contact name' },
      { field: data.shipper?.phone, name: 'Mobile number' },
      { field: data.shipper?.email, name: 'Email' },
      { field: data.shipper?.country, name: 'Country' },
      { field: data.shipper?.address1, name: 'Address' },
      { field: data.shipper?.postalCode, name: 'Postal code' },
      { field: data.shipper?.city, name: 'City / Town' },
      { field: data.shipper?.state, name: 'Province / Region / State' },
      { field: data.shipper?.reference, name: 'Reference' },
    ];
    const shipperDetailsValidations: CustomValidationField[] = [
      { field: data.shipper.details?.company, name: 'Pickup Details > Company' },
      { field: data.shipper.details?.contactName, name: 'Pickup Details > Contact name' },
      { field: data.shipper.details?.phone, name: 'Pickup Details > Mobile number' },
      { field: data.shipper.details?.email, name: 'Pickup Details > Email' },
      { field: data.shipper.details?.country, name: 'Pickup Details > Country' },
      { field: data.shipper.details?.address1, name: 'Pickup Details > Address' },
      { field: data.shipper.details?.postalCode, name: 'Pickup Details > Postal code' },
      { field: data.shipper.details?.city, name: 'Pickup Details > City / Town' },
      { field: data.shipper.details?.state, name: 'Pickup Details > Province / Region / State' },
    ];

    // Importer validations
    const importerValidations: CustomValidationField[] = [
      { field: data.importer?.company, name: 'Company' },
      { field: data.importer?.contactName, name: 'Contact name' },
      { field: data.importer?.phone, name: 'Mobile number' },
      { field: data.importer?.email, name: 'Email' },
      { field: data.importer?.country, name: 'Country' },
      { field: data.importer?.address1, name: 'Address' },
      { field: data.importer?.postalCode, name: 'Postal code' },
      { field: data.importer?.city, name: 'City / Town' },
      { field: data.importer?.state, name: 'Province / Region / State' },
      { field: data.importer?.reference, name: 'Reference' },
    ];
    const importerDetailsValidations = [
      { field: data.importer.details?.company, name: 'Delivery Details > Company' },
      { field: data.importer.details?.contactName, name: 'Delivery Details > Contact name' },
      { field: data.importer.details?.phone, name: 'Delivery Details > Mobile number' },
      { field: data.importer.details?.email, name: 'Delivery Details > Email' },
      { field: data.importer.details?.country, name: 'Delivery Details > Country' },
      { field: data.importer.details?.address1, name: 'Delivery Details > Address' },
      { field: data.importer.details?.postalCode, name: 'Delivery Details > Postal code' },
      { field: data.importer.details?.city, name: 'Delivery Details > City / Town' },
      { field: data.importer.details?.state, name: 'Delivery Details > Province / Region / State' },
    ];

    const validateGoodsSection = (goodsData: Goods[], sectionName: string) => {
      const section: ErrorFormat = { tab: sectionName, list: [] };

      goodsData.forEach(({
        name, permit, msdsDocument, dangerousGoods, length, width, height, ...rest
      }: Goods, i: number) => {
        validateFields(
          [
            { field: name, name: `${i + 1} > Name` },
            { field: permit, name: `${i + 1} > Permit` },
            {
              field: dangerousGoods ? !!(msdsDocument as any)?.fileList?.length : true,
              name: `${i + 1} > MSDS/SDS Document`,
            },
            ...(!data.orderType?.isAssistanceNeeded ? [
              // { field: rest.hsCode, name: `${i + 1} > HS Code`, roles: ['user'] },
              { field: rest.packaging, name: `${i + 1} > Kind of Package`, roles: ['user'] },
              // { field: rest.materialAndCode, name: `${i + 1} > Material and Code`, roles: ['user'] },
              { field: rest.quantity, name: `${i + 1} > Number of Packages`, roles: ['user'] },
              { field: rest.value, name: `${i + 1} > Value`, roles: ['user'] },
              { field: rest.net, name: `${i + 1} > Net Quantity per Package`, roles: ['user'] },
              { field: rest.gross, name: `${i + 1} > Gross Weight per Package`, roles: ['user'] },
              { field: length && width && height, name: `${i + 1} > Length / Width / Height`, roles: ['user'] },
              { field: rest.volume, name: `${i + 1} > Volume per Package`, roles: ['user'] },
            ] as CustomValidationField[] : []),
          ],
          section.list,
        );
      });

      if (section.list.length > 0) {
        listError.push(section);
      }
    };

    const validatePackagesSection = (
      packagesData: ClientDataPackages[],
      packageSummary: string | undefined,
      sectionName: string,
    ) => {
      const section: ErrorFormat = { tab: sectionName, list: [] };

      if (!packageSummary?.length) {
        section.list.push('Shipment summary');
      }

      if ((packageSummary?.length || 0) > 70) {
        section.list.push('Shipment summary can\'t be longer than 70 characters');
      }

      // Validate each package
      packagesData.forEach(({
        quantity, length, width, height, items, weight, value,
      }: ClientDataPackages, i: number) => {
        validateFields(
          [
            { field: quantity, name: `${i + 1} > Number of Packages`, roles: ['user'] },
            { field: length && width && height, name: `${i + 1} > Length / Width / Height`, roles: ['user'] },
            { field: (items || []).length, name: `${i + 1} > Products`, roles: ['user'] },
            { field: weight, name: `${i + 1} > Weight`, roles: ['user'] },
            { field: value, name: `${i + 1} > Value`, roles: ['user'] },
          ],
          section.list,
        );
      });

      if (section.list.length > 0) {
        listError.push(section);
      }
    };

    validateSection('General', generalValidations);
    validateSection('Shipper', shipperValidations);
    if (data.shipper?.isAddressDifferent) {
      validateSection('Pickup Details', shipperDetailsValidations);
    }
    validateSection('Consignee', importerValidations);
    if (data.importer?.isAddressDifferent) {
      validateSection('Delivery Details', importerDetailsValidations);
    }
    /** If validation not simplified, validate these forms too: */
    if (!shouldSkipSection('Products') && data.goods?.goods?.length > 0) {
      validateGoodsSection(data.goods.goods, 'Products');
    }
    if (!shouldSkipSection('Packages') && data.packages?.packages?.length > 0) {
      validatePackagesSection(data.packages.packages, data.packages.packageSummary, 'Products > Packages');
    }

    /* if (!data.orderType?.isAssistanceNeeded) {
      const documentsValidations: CustomValidationField[] = [
        { field: data.documents?.proformaInvoices, name: 'Proforma invoice', roles: ['user'] },
      ];

      validateSection('Documents', documentsValidations);
    } */

    if (listError.length > 0) {
      message.destroy();
      refCloseMessage.current = message.error(
        (
          <>
            <span style={{ fontWeight: 'bold' }}>
              Please fill in all required fields
            </span>
            <div
              style={{
                textAlign: 'left',
                maxHeight: '90vh',
                overflow: 'auto',
                margin: '8px 0',
              }}
            >
              {listError.map(({ tab, list }, i) => (
                <div key={tab} style={{ marginTop: 8, marginBottom: i === (listError.length - 1) ? 0 : 12 }}>
                  <span style={{ fontWeight: 'bold' }}>
                    {tab}
                    :
                  </span>
                  {list.map((err) => (
                    <div key={err} style={{ marginLeft: 16 }}>{err}</div>
                  ))}
                </div>
              ))}
            </div>
          </>
        ),
        5,
        () => {
          refCloseMessage.current = undefined;
        },
      );

      return false;
    }

    return true;
  }, []);
}

export const formNames = ['orderType', 'general', 'shipper', 'importer', 'goods', 'packages', 'documents',
  'delivery'] as const;
export type FormNames = typeof formNames[number];

export type Validator = (form: FormInstance) => boolean;

export interface FormName {
  formName: FormNames;
}

export interface OrderContextFormProps {
  initialStates: { [key in FormNames]: AnyObject }, // key: FormNames
  setInitialState: (key: FormNames, validator: AnyObject) => void,
  isValid: boolean;
  generalForm: FormInstance;
  shipperForm: FormInstance;
  consigneeForm: FormInstance;
  goodsForm: FormInstance;
  packagesForm: FormInstance;
  documentsForm: FormInstance;
  deliveryForm: FormInstance;
  orderTypeForm: FormInstance;
  overviewForm: FormInstance;
  setValidator: (key: FormNames, validator: Validator) => void;
  forms: { [key in FormNames]: FormInstance };
  triggerValidationAllForm: () => boolean;
  loadingFile: boolean,
  setLoadingFile: (key: string, value: boolean) => void,
  orderProformaInvoicesGet: FetchGet<
    OrderProformaInvoicesData,
    undefined,
    DefaultFetchError,
    OrderProformaInvoicesData
  >
  ordersTransportDocumentsGet: FetchGet<
    OrdersTransportDocumentsResponse,
    undefined,
    OrdersTransportDocumentsResponseError,
    OrdersTransportDocumentsResponse
  >
  setStopBlocker: (value: boolean) => void;
  orderCreate: FetchCreate<Order, DefaultFetchError, OrderCreateParams>;
  orderUpdate: FetchUpdate<Order, DefaultFetchError, OrderUpdateParams>;
  orderSave: OrderSaveType;
  generateProcess: null |(() => void);
  ordersTransportDocumentsGenerate: () => void;
  orderProformaInvoicesGenerate: () => void;
  handleNextTab: () => void;

  isEmailsChanged: boolean;
  setIsEmailsChanged: (val: boolean) => void;
}

type OrderSaveType = (
  e?: React.MouseEvent<HTMLElement>,
  callback?: () => void,
  extraProps?: {
    query?: string;
    isSimplifiedValidation?: IsSimplifiedFormType;
  },
) => Promise<Order | void | null>; // void;

const defaultValue: OrderContextFormProps = {
  initialStates: {} as { [key in FormNames]: AnyObject },
  setInitialState: (key: FormNames, validator: AnyObject) => {
    // default
  },
  isValid: false,
  generalForm: {} as FormInstance,
  shipperForm: {} as FormInstance,
  consigneeForm: {} as FormInstance,
  goodsForm: {} as FormInstance,
  packagesForm: {} as FormInstance,
  documentsForm: {} as FormInstance,
  deliveryForm: {} as FormInstance,
  orderTypeForm: {} as FormInstance,
  overviewForm: {} as FormInstance,
  setValidator: (key: FormNames, validator: Validator) => {
    // default
  },
  forms: {} as { [K in FormNames]: FormInstance<any> },
  triggerValidationAllForm: () => false, // { // default },
  loadingFile: false,
  setLoadingFile: (key: string, value: boolean) => {
    // default
  },
  orderProformaInvoicesGet: {} as FetchGet<
    OrderProformaInvoicesData,
    undefined,
    DefaultFetchError,
    OrderProformaInvoicesData
  >,
  ordersTransportDocumentsGet: {} as FetchGet<
    OrdersTransportDocumentsResponse,
    undefined,
    OrdersTransportDocumentsResponseError,
    OrdersTransportDocumentsResponse
  >,
  orderCreate: {} as FetchCreate<Order, DefaultFetchError, OrderCreateParams>,
  orderUpdate: {} as FetchUpdate<Order, DefaultFetchError, OrderUpdateParams>,
  orderSave: async (e, callback, extraProps) => {
    // default
  },
  generateProcess: null,
  ordersTransportDocumentsGenerate: () => {
    // default
  },
  orderProformaInvoicesGenerate: () => {
    // default
  },
  setStopBlocker: (value: boolean) => {
    // default
  },
  handleNextTab: () => undefined,

  isEmailsChanged: false,
  setIsEmailsChanged: () => undefined,
};

export const OrderContextForm = createContext<OrderContextFormProps>(defaultValue);

function OrderProviderForm({
  children,
}: SimpleProviderProps) {
  const isValidForm = useValidForm();
  const { message } = App.useApp();
  const { id } = useParams();
  const [loadingFiles, setLoadingFiles] = useState<{ [key: string]: boolean }>({});
  const loadingFile = useMemo(() => Object.values(loadingFiles).some((value) => value), [loadingFiles]);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    order, handleClientOrderData,
  } = useOrderContext();

  const [generalForm] = Form.useForm();
  const [shipperForm] = Form.useForm();
  const [consigneeForm] = Form.useForm();
  const [goodsForm] = Form.useForm();
  const [packagesForm] = Form.useForm();
  const [documentsForm] = Form.useForm();
  const [deliveryForm] = Form.useForm();
  const [emailsForm] = Form.useForm();

  const [orderTypeForm] = Form.useForm();

  const [overviewForm] = Form.useForm();

  const forms = useMemo<{ [key in FormNames]: FormInstance }>(() => ({
    general: generalForm,
    shipper: shipperForm,
    importer: consigneeForm,
    goods: goodsForm,
    packages: packagesForm,
    documents: documentsForm,
    delivery: deliveryForm,
    orderType: orderTypeForm,
    emails: emailsForm,
  }), []);
  const navigate = useNavigate();
  const { user } = useAuth();
  const orderCreate = useOrderCreate(user?.role === 'user');
  const orderUpdate = useOrderUpdate(id, user?.role === 'user');

  useMessageError([orderCreate, orderUpdate]);
  useMessageSuccess([orderCreate, orderUpdate], 'Changes saved successfully');

  useEffect(() => {
    /** Update form data after orderUpdate */
    if (orderUpdate.data && !orderUpdate.error && !orderUpdate.loading) {
      handleClientOrderData(orderUpdate.data);
      order.setRequestData(orderUpdate.data);
    }
  }, [orderUpdate.data]);

  useEffect(() => {
    if (!orderCreate.error && !orderCreate.loading && orderCreate.data) {
      setTimeout(() => setStopBlocker(false), 100);
      const currentTab = searchParams.get('tab');

      navigate(`/orders/${orderCreate.data.id}/edit${currentTab ? `?tab=${currentTab}` : ''}`);
    }
  }, [orderCreate.error, orderCreate.loading, orderCreate.data]);

  const orderProformaInvoicesGet = useOrderProformaInvoicesGet(id, user?.role === 'user');
  const ordersTransportDocumentsGet = useOrdersTransportDocumentsGet(id);
  const ordersTransportDocumentsGetRef = useRef(null);

  const [isEmailsChanged, setIsEmailsChanged] = useState<boolean>(false);

  const [stopBlocker, setStopBlocker] = useState(false);
  const [isValid, setValid] = useState<boolean>(false);
  const [initialStates, setInitialStates] = useState<{ [key in FormNames]: AnyObject }>(
    {} as { [key in FormNames]: AnyObject },
  );
  const [validators, setValidators] = useState<{ [key in FormNames]: Validator}>(
    {} as { [key in FormNames]: Validator },
  );

  const [triggerChange, setTriggerChange] = useState(Date.now());
  const routeBlocker = useRouteBlocker(
    () => (orderProformaInvoicesGet.data?.location ? false : (formNames.some((nameForm) => {
      if (stopBlocker) { // TODO replace with blocker-disabler context value
        return false;
      }

      if (!initialStates[nameForm]) {
        try {
          return getOnlyValueRecursive(forms[nameForm].getFieldsValue()) !== '';
        } catch {
          return true;
        }
      }

      const { id: _, createdAt: __, ...state } = initialStates[nameForm] || {};

      try {
        return getOnlyValueRecursive(state) !== getOnlyValueRecursive(forms[nameForm].getFieldsValue());
      } catch {
        return true;
      }
    }))),
    [stopBlocker, initialStates, forms, triggerChange, orderProformaInvoicesGet.data?.location],
  );

  useMessageError([orderProformaInvoicesGet]);
  const contextHolderModalError = useMessageModalError([
    [ordersTransportDocumentsGet, 'Transport Documents generation error'],
  ]);

  useEffect(() => {
    if (ordersTransportDocumentsGet.data
      && !ordersTransportDocumentsGet.error
      && !ordersTransportDocumentsGet.loading
    ) {
      if (ordersTransportDocumentsGetRef.current !== ordersTransportDocumentsGet.data) {
        message.success('Transport Documents generated successfully');
      }
      // eslint-disable-next-line no-unused-expressions
      ordersTransportDocumentsGetRef.current === ordersTransportDocumentsGet.data;
    }
  }, [ordersTransportDocumentsGet.data]);

  useEffect(() => {
    if (orderProformaInvoicesGet.data?.location) {
      try {
        // Automatically downloads files when data appears
        // window.location.assign(orderProformaInvoicesGet.data?.location);
      } catch (error) {
        console.warn(error);
      }
    }
  }, [orderProformaInvoicesGet.data]);

  const triggerValidationAllForm = useCallback((): boolean => {
    setTriggerChange(Date.now());

    const newValue = !loadingFile && !formNames.some((key) => {
      if (validators[key]) {
        // console.log(key, validators[key](forms[key]));

        return !validators[key](forms[key]);
      }

      return false;
    });

    setValid(newValue);

    return newValue;
  }, [loadingFile, validators, forms, setTriggerChange]);

  const setValidator = useCallback((key: FormNames, validator: Validator) => {
    setValidators((beforeState) => ({ ...beforeState, [key]: validator }));
  }, []);

  const setInitialState = useCallback((key: FormNames, initialState: AnyObject) => {
    setInitialStates((beforeState) => ({ ...beforeState, [key]: initialState }));
  }, []);

  const setLoadingFile = useCallback((key: string, value: boolean) => {
    if (value) {
      setLoadingFiles((beforeState) => ({ ...beforeState, [key]: value }));
    } else {
      setLoadingFiles((beforeState) => {
        if (typeof beforeState[key] !== 'undefined') {
          // eslint-disable-next-line no-param-reassign
          delete beforeState[key];
        }

        return { ...beforeState };
      });
    }
  }, []);

  const [generateProcess, setGenerateProcess] = useState<null |(() => void)>(null);

  const ordersTransportDocumentsGenerate = () => {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    setGenerateProcess(() => () => {
      setGenerateProcess(null);
      ordersTransportDocumentsGet.fetch();
    });
    orderSave(undefined, undefined, { isSimplifiedValidation: true });
  };
  const orderProformaInvoicesGenerate = () => {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    setGenerateProcess(() => () => {
      setGenerateProcess(null);
      orderProformaInvoicesGet.fetch();
    });
    orderSave(undefined, undefined, { isSimplifiedValidation: true });
  };

  useEffect(
    () => {
      if (generateProcess && typeof generateProcess === 'function') {
        try {
          if (orderUpdate.data && !orderUpdate.error && !orderUpdate.loading) {
            generateProcess();
          }

          if (orderCreate.data && !orderCreate.error && !orderCreate.loading) {
            setTimeout(generateProcess, 500);
          }
        } catch (error) {
          console.warn('Generate process:', error);
        }
      }
    },
    [orderUpdate.data, orderCreate.data],
  );

  const orderSave: OrderSaveType = async (e, callback, extraProps) => {
    try {
      const queryParams = extraProps?.query;
      const isSimplifiedValidation = extraProps?.isSimplifiedValidation;

      if (typeof callback === 'function') {
        // eslint-disable-next-line unicorn/consistent-function-scoping
        setGenerateProcess(() => () => {
          setGenerateProcess(null);
          callback();
        });
      }

      e?.preventDefault();

      const list: ClientData | AnyObject = {};

      formNames.forEach((key) => {
        forms[key].validateFields();
        list[key] = forms[key].getFieldsValue();
      });

      if (isValidForm(list, isSimplifiedValidation)) {
        const data = getServerData(list as ClientData, user?.role);

        setStopBlocker(true);

        return await (id ? orderUpdate : orderCreate)
          .fetch(data, queryParams ? `${id || ''}${queryParams}` : undefined)
          .finally(() => {
            setInitialState('general', list.general);
            setInitialState('shipper', list.shipper);
            setInitialState('importer', list.importer);
            setInitialState('goods', list.goods);
            setInitialState('packages', list.packages);
            setInitialState('documents', list.documents);
            setInitialState('delivery', list.delivery);
            /** Client flow fields */
            setInitialState('orderType', list.orderType);
          });
      }
    } catch {
      setGenerateProcess(null);

      throw new Error('Form validation failed!');
    }
    setGenerateProcess(null);

    return undefined; // throw new Error('Form validation failed!');
  };

  /** Opens next tab if it's available and current tab have no errors. */
  const handleNextTab = useCallback(async () => {
    const currentTab = searchParams.get('tab') || 'Order type'; // filteredTabs[0].key
    // user?.role === 'user' ? 'Order type' : 'General data'

    /** Validate the form corresponding to the current tab */
    const formName = tabsFormsEnum[currentTab as keyof typeof tabsFormsEnum] as FormNames; // Get the form name
    const currentForm = forms[formName]; // Get the corresponding form

    try {
      await currentForm.validateFields();
      if (formName === 'goods') { await forms.packages.validateFields(); }
      // On 'Products' tab we have 2 forms, so if current form is goods - check packages too

      const filteredTabs = orderTabs.filter((tab) => (
        !tab.roles || (tab.roles || []).includes(user?.role || '')));
      const currentTabIndex = filteredTabs.findIndex((tab) => tab.key === currentTab);

      if (currentTabIndex !== -1 && currentTabIndex < filteredTabs.length - 1) {
        const nextTab = filteredTabs[currentTabIndex + 1];

        if (nextTab) {
          setSearchParams({ tab: nextTab.key });
        }
      }
    } catch (error_) {
      message.error('Please fill in all required fields');
      console.log('Form validation failed', error_);
    }
  }, [searchParams, setSearchParams, user?.role]);

  const memoizedValue = useMemo(() => ({
    setStopBlocker,
    loadingFile,
    setLoadingFile,
    initialStates,
    setInitialState,
    isValid,
    generalForm,
    shipperForm,
    consigneeForm,
    goodsForm,
    packagesForm,
    documentsForm,
    deliveryForm,
    orderTypeForm,
    overviewForm,
    forms,
    setValidator,
    triggerValidationAllForm,
    orderProformaInvoicesGet,
    ordersTransportDocumentsGet,
    orderCreate,
    orderUpdate,
    orderSave,
    generateProcess,
    ordersTransportDocumentsGenerate,
    orderProformaInvoicesGenerate,
    // customer actions
    handleNextTab,

    isEmailsChanged,
    setIsEmailsChanged,
  }), [
    setStopBlocker,
    loadingFile,
    setLoadingFile,
    initialStates,
    setInitialState,
    isValid,
    generalForm,
    shipperForm,
    consigneeForm,
    goodsForm,
    packagesForm,
    documentsForm,
    deliveryForm,
    orderTypeForm,
    overviewForm,
    forms,
    setValidator,
    triggerValidationAllForm,
    orderProformaInvoicesGet,
    ordersTransportDocumentsGet,
    orderCreate,
    orderUpdate,
    orderSave,
    generateProcess,
    ordersTransportDocumentsGenerate,
    orderProformaInvoicesGenerate,
    handleNextTab,

    isEmailsChanged, setIsEmailsChanged,
  ]);

  return (
    <OrderContextForm.Provider value={memoizedValue}>
      {children}
      <div>
        {routeBlocker.contextHolder}
      </div>
      <div>
        {contextHolderModalError}
      </div>
    </OrderContextForm.Provider>
  );
}

export default OrderProviderForm;

export const useOrderContextForm = ():
  OrderContextFormProps => React.useContext(OrderContextForm);
