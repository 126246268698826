/* eslint-disable max-len */

import * as yup from 'yup';
import 'yup-phone';
import { Rule, RuleObject } from 'rc-field-form/lib/interface';
import { AnyObject } from '@triare/auth-redux';

export type ValidatorProps = (string | false)[];

export const createValidatorTextField = (text: ValidatorProps, required = true, maxLen = 250) => {
  let validator = yup.string()
    .max(maxLen, text[1] || `Cannot be more than ${maxLen} characters`);

  if (required) {
    validator = validator
      .min(3, text[0] || 'Must be at least 3 characters')
      .required(text[2] || 'Required');
  }

  return validator;
};

export const textField: yup.StringSchema = createValidatorTextField([]);

export const createValidatorName = (text: ValidatorProps, maxLength = 50) => yup.string()
  .min(3, text[0] || 'Must be at least 3 characters')
  .max(maxLength, text[1] || `Cannot be more than ${maxLength} characters`)
  .required(text[2] || 'Required');

export const name: yup.StringSchema = createValidatorName([]);

export const createValidatorUsername = (text: ValidatorProps) => yup.string()
  .min(6, text[0] || 'Must be at least 6 characters')
  .max(100, text[1] || 'Cannot be more than 100 characters')
  .required(text[2] || 'Required');

export const username: yup.StringSchema = createValidatorUsername([]);

export const password: yup.StringSchema = yup.string()
  .min(12, 'Password must be at least 12 characters')
  .max(100, 'Too Long! Maximum 100 characters')
  .matches(/^(?=.*\d)(?=.*\D)([\D\d]+)$/, 'Must contain numbers and letters')
  .matches(/[^\dA-Za-z]|[A-Z]/, 'Must contain one uppercase letter or special symbol')
  .matches(/[a-z]/, 'Must contain one lowercase letter')
  .matches(/^\S+$/g, 'Should not contain whitespaces')
  .required('Required');

export const confirmPassword: yup.StringSchema = yup.string().required('Required');

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const phone: yup.StringSchema = yup.string().phone()
  .max(15, 'Please enter a valid phone number.')
  .required('Required');

export const createValidatorEmail = (text: ValidatorProps) => yup.string()
  .email(text[0] || 'Invalid email')
  .required(text[1] || 'Required')
  .matches(/^.+.\w{2}$/, text[2] || 'At least two characters after the period');

export const email: yup.StringSchema = createValidatorEmail([]);

export const confirmEmail: yup.StringSchema = yup.string()
  .oneOf([yup.ref('email')], 'Email must match')
  .required('Required');

export const zipCode: yup.StringSchema = yup.string()
  .min(5, 'Please enter a valid Zip Code.')
  .max(10, 'Please enter a valid Zip Code.')
  .matches(/^\d{5}$|^\d{5}-\d{4}$/, 'Please enter a valid Zip Code.')
  .required('Required');

export const validationSignUpEmailOnly = yup.object().shape({
  email,
});

export const validationSignUpEmail = yup.object().shape({
  email,
});

export const validationSignUpEmailAndPassword = yup.object({
  email,
  password,
  confirmPassword,
});

export const validationSignUpPhoneOnly = yup.object().shape({
  phone,
});

export const validationSignUpPhoneAndPasswordOnly = yup.object().shape({
  phone,
  password,
  confirmPassword,
});

export const validationSignUp = yup.object().shape({
  password,
  email,
  phone,
  firstName: name,
  lastName: name,
  address: name,
});

export const validationGeneralInfo = yup.object().shape({
  name,
  address: textField,
  phone,
  email,
});

export const validationUserInfo = yup.object().shape({
  firstName: name,
  lastName: name,
  phone,
  email,
});

export const validationChangePassword = yup.object().shape({
  currentPassword: password,
  password,
});

export const validationNewPassword = yup.object().shape({
  password,
});

export const validationChangeEmail = yup.object().shape({
  email,
  confirmEmail,
});

export const validationUserAdd = yup.object().shape({
  lastName: textField,
  firstName: textField,
  phone,
  email,
});

export const validationApplicant = yup.object().shape({
  indemnityNumber: name,
  ahpra: yup.string()
    .min(5, 'Must be at least 5 characters')
    .max(15, 'Cannot be more than 15 characters.')
    .required('Required'),
  address: textField,
  lastName: textField,
  firstName: textField,
  phone,
  email,
});

export const validationSignInEmailAndPassword = yup.object().shape({
  email,
  password,
});

export const validationSignInUsernameAndPassword = yup.object().shape({
  username,
  password,
});

export const validationSignInPhoneOnly = yup.object().shape({
  phone,
});

export const validationSignInPhoneAndPassword = yup.object().shape({
  phone,
  password,
});

export const validationForgotPassword = yup.object().shape({
  email,
});

export const validationSignUpCompanyAdmin = yup.object({
  fullName: createValidatorName([], 250),
  email,
  password,
  confirmPassword,
});

const { shape } = yup.object();

export const createRulesForAntd = (yupRule: ReturnType<typeof shape>, getValue?: () => AnyObject): Rule => ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  async validator({ field }: RuleObject, value) {
    if (field.includes('.') && getValue) {
      await yupRule.validateSyncAt(field, getValue());
    } else {
      await yupRule.validateSyncAt(field, { [field]: value });
    }
  },
});

const validatorRules: {[key: string]: Rule} = {};

export const getRulesForAntd = (yupRule: ReturnType<typeof shape>): Rule => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (validatorRules[yupRule.name]) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return validatorRules[yupRule.name];
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  validatorRules[yupRule.name] = createRulesForAntd(yupRule);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return validatorRules[yupRule.name];
};

export default {
  validationSignUp,
  validationChangePassword,
  validationNewPassword,
  validationChangeEmail,
  validationSignIn: validationSignInEmailAndPassword,
  validationForgotPassword,
  password,
  email,
  confirmEmail,
};
