import React from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';
import { Order, useOrderMarkMeAsManager } from '../../../../../hooks/api/order';
import { OrderStatus } from '../../Adapter/enums';
import { useOrderContext } from '../../View/context';
import { isRoleEnough } from '../../../../../enums/user';
import { useAuth } from '../../../../../store/auth';

interface MarkMeAsManagerButtonProps {
  afterSuccess?: (data: Order | null) => void;
}

function MarkMeAsManagerButton({
  afterSuccess = async () => undefined,
}: MarkMeAsManagerButtonProps) {
  const { id } = useParams();
  const { clientOrderData } = useOrderContext();
  const { user } = useAuth();
  const orderMarkMeAsManager = useOrderMarkMeAsManager(id);

  const isUserAdmin = isRoleEnough(user?.role, 'admin');

  const handleClick = () => {
    orderMarkMeAsManager.fetch()
      .then(afterSuccess);
  };

  if (clientOrderData?.manager?.id !== user?.id
    && isUserAdmin && (clientOrderData?.company && (
    clientOrderData?.status && [
      OrderStatus.DRAFT,
      OrderStatus.IN_PROGRESS,
      OrderStatus.TO_BE_PAID,
      OrderStatus.REJECTED,
      OrderStatus.DECLINED,
      OrderStatus.PENDING,
      OrderStatus.EXPIRED,
      OrderStatus.PROCESSING,
      OrderStatus.READY_FOR_DELIVERY,
    ].includes(clientOrderData?.status)
  ))) {
    return (
      <Button
        type="default"
        onClick={(e) => {
          e.preventDefault();
          handleClick();
        }}
      >
        Mark me as manager
      </Button>
    );
  }

  return null;
}

export default MarkMeAsManagerButton;
