import React, { useEffect, useState } from 'react';
import {
  Button, Col, Form, Input, InputNumber, Modal, Row, Select, Tooltip, Typography,
} from 'antd';
import { useParams } from 'react-router-dom';
import { DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import clsx from 'clsx';
import { FormItemProps } from 'antd/es/form/FormItem';
import {
  Invoice,
  InvoiceCreateParams,
  prioritizedInvoiceServices, ServiceDetails,
  useInvoicesGetById,
  useInvoiceUpdate,
  useOrderInvoiceCreate,
} from '../../../../../../../hooks/api/invoices';
import { enumToOptionsArray, filterObjectProps, pickObjFields } from '../../../../../../../utils';
import { ClientDataShipper, CurrencyType } from '../../../../Adapter';
import ChooseServiceButton from './ChooseServiceButton';
import { ServiceUnitShortEnum, useDefaultServicesGet } from '../../../../../../../hooks/api/services';
import { useMessageError } from '../../../../../../../hooks/common';
import { useOrderContext } from '../../../../View/context';
import useInvoiceCalculations from '../../../../../Invoices/View/DetailsContainer/useInvoiceCalculations';
import { sortServicesByPriority } from '../../../Overview/SummaryBox';
import { useOrderContextForm } from '../../../context';
import ChangeCurrencyButton from './ChangeCurrencyButton';
import { SelectedItemPaperwork } from '../../index';

import styles from './index.module.scss';

export interface FormFields extends InvoiceCreateParams {
  regularServices: InvoiceCreateParams['services'],
}

const requiredField: FormItemProps['rules'] = [{ required: true, message: 'Required' }];

const formInitialState: FormFields = {
  description: '',
  regularServices: [],
  services: [],
  currency: 'CHF',
  conversionRate: 0,
  roundDifference: 0,
};

const serviceInitialVal: Partial<ServiceDetails> = {
  name: '',
  kind: undefined,
  value: 0,
  valueCHF: 0,
  tax: 0,
  units: 'pieces',
  quantity: 1,
};

const getFilteredServices = (services?: Invoice['services']) => {
  const regular = (services || []).filter((item) => prioritizedInvoiceServices.includes(item?.kind));
  const custom = (services || []).filter((item) => !prioritizedInvoiceServices.includes(item?.kind));

  return {
    regular,
    custom,
  };
};

/** Finds main invoice with specific services like 'shippingCost', 'platformUsageFee', 'insuranceFee' */
export const findMainInvoice = (invoices: Invoice[]) => (invoices || [])
  .find((invoice) => invoice.services.some((item) => (
    prioritizedInvoiceServices.includes(item.kind)
  ))) || (invoices || [])?.[0];

interface ModalFormInvoiceProps {
  open: boolean;
  handleClose: () => void;
  afterSave?: (res: Invoice) => void;
  handleSelectedItem?: (props: SelectedItemPaperwork) => void;
  itemId: string;
}

function ModalFormInvoice({
  open, handleClose, afterSave, itemId, handleSelectedItem,
}: ModalFormInvoiceProps) {
  const { id: orderId = '' } = useParams();
  const [form] = Form.useForm();
  const invoiceGetById = useInvoicesGetById('admin');
  const orderInvoiceCreate = useOrderInvoiceCreate(orderId);
  const orderInvoiceUpdate = useInvoiceUpdate('admin');
  const {
    forms: { shipper: shipperForm, importer: consigneeForm }, orderSave, orderUpdate,
  } = useOrderContextForm();
  const { order, clientOrderData } = useOrderContext();

  const defaultServicesGet = useDefaultServicesGet();
  const deliveryMultiplier = defaultServicesGet.data?.deliveryMultiplayer || 1.5;

  const fetchInvoice = async () => {
    if (itemId) {
      return await invoiceGetById.fetch(undefined, itemId);
    }

    return null;
  };

  useEffect(() => {
    if (itemId) {
      fetchInvoice();
    }
  }, [itemId]);

  const [initialValues, setInitialValues] = useState<Partial<FormFields>>(formInitialState);

  useEffect(() => {
    if (itemId && invoiceGetById.data && !invoiceGetById.loading && !invoiceGetById.error) {
      const newData = pickObjFields(
        invoiceGetById.data,
        ['description', 'currency', 'conversionRate', 'roundDifference'],
      );

      const { regular, custom } = getFilteredServices(invoiceGetById.data.services);

      setInitialValues({
        ...newData,
        regularServices: regular.sort(sortServicesByPriority),
        services: custom, // .map((item) => filterObjectProps(item, ['kind', 'valueCHF'])),
      });
    }
  }, [invoiceGetById.data, itemId]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  /** Util function */
  const regularServicesWatch: Invoice['services'] = Form.useWatch('regularServices', form) || [];
  const servicesWatch: Invoice['services'] = Form.useWatch('services', form) || [];
  const roundDifferenceWatch: number = Form.useWatch('roundDifference', form) || 0;
  const currencyWatch: CurrencyType = Form.useWatch('currency', form) || 'CHF';

  const {
    subtotal,
    totalVAT,
    amountDue,
  } = useInvoiceCalculations(clientOrderData, [...regularServicesWatch, ...servicesWatch], roundDifferenceWatch);

  const handleSyncDescription = () => {
    const shipper: ClientDataShipper = shipperForm.getFieldsValue();
    const consignee: ClientDataShipper = consigneeForm.getFieldsValue();
    const newValue = `${shipper.company || '-'}, ${shipper.country || '-'} ${shipper.city || '-'} - ${
      consignee.company || '-'}, ${consignee.country || '-'} ${consignee.city || '-'}`;

    form.setFieldValue('description', newValue);
  };

  const handleSyncServices = async () => {
    // TODO add save invoice modal before?
    await orderSave(undefined, undefined, { query: '?updateInvoice=yes' })
      .then((res) => {
        if (res?.id) {
          const mainInvoice = findMainInvoice(res.invoices || []);

          handleSelectedItem?.(mainInvoice
            ? { type: 'invoice', id: mainInvoice.id, item: mainInvoice }
            : { type: null, id: '', item: undefined });
          handleClose();
        }
      });
  };

  /** Submit */
  const callbackFunc = (res: Invoice | null) => {
    if (res?.id) {
      order.fetch();
      afterSave?.(res);
      handleClose();
    }
  };

  const handleSubmit = () => {
    if (!orderId) return;

    form.validateFields()
      .then(({ regularServices, services, ...values }: FormFields) => {
        const newServices = [...(regularServices || []), ...(services || [])];
        const newValues: InvoiceCreateParams = {
          ...values,
          services: newServices.map((item) => filterObjectProps(item, ['kind'])),
        };

        if (itemId) {
          orderInvoiceUpdate.fetch(newValues, itemId)
            .then(callbackFunc);
        } else {
          orderInvoiceCreate.fetch(newValues)
            .then(callbackFunc);
        }
      })
      .catch((_error) => console.log(_error));
  };

  const handleCloseClick = () => {
    setInitialValues(formInitialState);
    handleClose();
  };

  useMessageError([invoiceGetById, orderInvoiceCreate, orderInvoiceUpdate]);

  return (
    <Modal
      title={itemId ? `Update Invoice ${invoiceGetById.data?.number || ''}` : 'Create Invoice'}
      open={open}
      onCancel={handleCloseClick}
      onOk={handleSubmit}
      okText="Save"
      okButtonProps={{
        loading: orderInvoiceCreate.loading || orderInvoiceUpdate.loading,
      }}
      width={940}
      destroyOnClose
      maskClosable={false}
      afterClose={() => form.setFieldsValue(formInitialState)}
    >
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <Row gutter={[16, 16]}>
          <Col span={21}>
            <Form.Item name="description">
              <TextArea rows={3} placeholder="Description" maxLength={300} />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Button
              icon={<ReloadOutlined />}
              onClick={handleSyncDescription}
              disabled={false}
            >
              Sync
            </Button>
          </Col>

          <Form.Item name={['currency']} style={{ display: 'none' }}>
            <Input />
          </Form.Item>
          <Form.Item name={['conversionRate']} style={{ display: 'none' }}>
            <Input />
          </Form.Item>

          <div className={clsx(styles.boxFullWidth, { [styles.hidden]: regularServicesWatch.length === 0 })}>
            <Col span={21}>
              <Form.List name="regularServices">
                {(fields) => (
                  <div className={clsx(styles.regularServices)}>
                    {fields.map((field) => {
                      const currentService = regularServicesWatch[field.name] || {};

                      return (
                        <div key={field.key} className={styles.regularServiceRow}>
                          <span>{currentService?.name || '-'}</span>

                          {['shippingCost', 'platformUsageFee', 'insuranceFee'].includes(currentService.kind) ? (
                            <Form.Item
                              name={[field.name, 'tax']}
                              normalize={(value) => (value && Number.parseFloat(value.toFixed(2))) || 0}
                            >
                              <InputNumber
                                prefix="VAT"
                                addonAfter="%"
                                style={{ width: '100%' }}
                                className={styles.inputNumber}
                                min={0}
                              />
                            </Form.Item>
                          ) : null}

                          {currentService.kind === 'shippingCost' && ['dhl', 'fedex']
                            .includes(clientOrderData?.delivery?.deliveryService || '') ? (
                              <Tooltip
                                title={clientOrderData?.delivery?.deliveryService === 'dhl' ? (
                                  <ul style={{
                                    paddingInlineStart: '20px',
                                  }}
                                  >
                                    {Object.keys(clientOrderData?.costBreakdown || {})?.length ? (
                                      Object.entries(clientOrderData?.costBreakdown || {}).map((item) => (
                                        <li key={item?.[0]}>
                                          <span>{item?.[0] || '-'}</span>
                                          {': '}
                                          <span>{item?.[1] || '-'}</span>
                                        </li>
                                      ))
                                    ) : null}
                                  </ul>
                                ) : ''}
                              >
                                <div className={styles.noMultiplierValue}>
                                  <span className={styles.title}>
                                    <text>{clientOrderData?.delivery?.deliveryService}</text>
                                    {' '}
                                    cost
                                  </span>
                                  <span>
                                    <b>
                                      {Number.parseFloat(((currentService?.value || 0) / deliveryMultiplier)
                                        .toFixed(2))}
                                    </b>
                                  </span>
                                </div>
                              </Tooltip>
                            ) : null}
                          <Form.Item
                            name={[field.name, 'value']}
                            normalize={(value) => (value && Number.parseFloat(value.toFixed(2))) || 0}
                          >
                            <InputNumber
                              prefix="Value"
                              addonAfter={currencyWatch}
                              style={{ width: '100%' }}
                              className={styles.inputNumber}
                              precision={2}
                              min={0}
                            />
                          </Form.Item>
                          <Form.Item
                            name={[field.name, 'valueCHF']}
                            style={{ display: 'none' }}
                            normalize={(value) => value || 0}
                          >
                            <InputNumber />
                          </Form.Item>
                        </div>
                      );
                    })}
                  </div>
                )}
              </Form.List>
            </Col>
            <Col span={3}>
              <Tooltip title="Update invoice services prices based on the selected delivery service">
                <Button
                  icon={<ReloadOutlined />}
                  onClick={handleSyncServices}
                  disabled={false}
                  loading={orderUpdate.loading}
                >
                  Sync
                </Button>
              </Tooltip>
            </Col>
          </div>

          <Col span={24}>
            <Form.List name="services">
              {(fields, { add, remove }) => (
                <div className={styles.column}>
                  <div className={clsx(styles.services, { [styles.hidden]: servicesWatch.length === 0 })}>
                    {fields.map((field) => {
                      const currentService = servicesWatch[field.name] || {};

                      return (
                        <div key={field.key} className={styles.serviceRow}>
                          <div className={styles.fieldsContainer}>
                            <Col span={24} style={{ marginBottom: '16px' }}>
                              <Form.Item name={[field.name, 'name']} rules={requiredField}>
                                <TextArea rows={1} placeholder="Name" maxLength={300} />
                              </Form.Item>
                            </Col>

                            <div className={styles.row}>
                              <Col span={8}>
                                <Form.Item
                                  name={[field.name, 'quantity']}
                                  normalize={(value) => value || 1} // || 0
                                >
                                  <InputNumber
                                    prefix="Qty."
                                    addonAfter={(
                                      <Form.Item name={[field.name, 'units']}>
                                        <Select options={enumToOptionsArray(ServiceUnitShortEnum)} />
                                      </Form.Item>
                                    )}
                                    style={{ width: '100%' }}
                                    className={clsx(styles.inputNumber, styles.withSelectAddon)}
                                    precision={0}
                                    min={1}
                                    defaultValue={1}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  name={[field.name, 'value']}
                                  normalize={(value) => (value && Number.parseFloat(value.toFixed(2))) || 0}
                                >
                                  <InputNumber
                                    prefix="Value/Unit"
                                    addonAfter={currencyWatch}
                                    style={{ width: '100%' }}
                                    className={styles.inputNumber}
                                    precision={2}
                                    min={0}
                                  />
                                </Form.Item>
                              </Col>
                              <Form.Item
                                name={[field.name, 'valueCHF']}
                                style={{ display: 'none' }}
                                normalize={(value) => value || 0}
                              >
                                <InputNumber />
                              </Form.Item>

                              <Col span={8}>
                                <Form.Item
                                  name={[field.name, 'tax']}
                                  normalize={(value) => (value && Number.parseFloat(value.toFixed(2))) || 0}
                                >
                                  <InputNumber
                                    prefix="VAT"
                                    addonAfter="%"
                                    style={{ width: '100%' }}
                                    className={styles.inputNumber}
                                    min={0}
                                  />
                                </Form.Item>
                              </Col>
                            </div>
                          </div>

                          <Button
                            icon={<DeleteOutlined />}
                            onClick={() => remove(field.name)}
                            className={styles.deleteButton}
                          />
                        </div>
                      );
                    })}
                  </div>

                  <div className={styles.actions}>
                    <Button onClick={() => add(serviceInitialVal)}>Add service</Button>
                    <ChooseServiceButton add={add} />
                  </div>
                </div>
              )}
            </Form.List>
          </Col>

          {/* Shown only for Invoices: */}
          <Col span={24}>
            <div className={styles.additionalItems}>
              <div className={styles.row}>
                <Col>
                  <Typography.Text>
                    Rounding difference
                  </Typography.Text>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={['roundDifference']}
                    normalize={(value) => {
                      if (value > 0) {
                        /** if user enters positive number - convert it to negative */
                        return -value;
                      }

                      return value || 0;
                    }}
                  >
                    <InputNumber
                      addonAfter="CHF"
                      style={{ width: '100%' }}
                      className={styles.inputNumber}
                      // max={0} // Replaced with normalize
                      precision={2}
                    />
                  </Form.Item>
                </Col>
              </div>
            </div>
          </Col>

          <Col span={24}>
            <div className={styles.footer}>
              <div>
                <ChangeCurrencyButton invoiceForm={form} />
              </div>

              <div>
                {[
                  { label: 'Subtotal', value: subtotal?.toFixed(2) },
                  { label: 'VAT', value: totalVAT }, // (Math.round(totalVAT * 100) / 100).toFixed(2)
                  { label: 'Amount', value: amountDue?.toFixed(2), bold: true },
                ].map(({ label, value, bold }) => (
                  <div className={styles.row} key={label}>
                    <span>
                      {label}
                      :
                    </span>
                    {' '}
                    <span style={bold ? { fontWeight: 600 } : undefined}>{`${value} ${currencyWatch}`}</span>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default ModalFormInvoice;
