/* eslint-disable max-len */
import React from 'react';
import dayjs from 'dayjs';
import { Col } from 'antd';
import clsx from 'clsx';
import { Invoice } from '../../../../../hooks/api/invoices';
import { ClientData } from '../../../Orders/Adapter';
import useInvoiceCalculations from './useInvoiceCalculations';
import { sortServicesByPriority } from '../../../Orders/Form/Overview/SummaryBox';
import { extractUnNumbersFromRes } from '../../../Orders/Form/Paperwork/Forms/ModalDeliveryNote';
import { CurrencyToSymbolEnum } from '../../../../../enums/general';

import styles from '../index.module.scss';

interface DetailsContainerProps {
  invoice: Omit<Invoice, 'status'> & { status: string } | undefined;
  orderData: ClientData | undefined | null;
  simplified?: boolean;
}

function ShippingDetails({ invoice, orderData }: DetailsContainerProps) {
  const { services, roundDifference } = invoice || {};
  const {
    contactName,
    company: shipperFromCompany,
    country: shipperFromCountry,
    isAddressDifferent: shipperIsAddressDifferent,
    details: shipperAddressDetails,
  } = orderData?.shipper || {};
  const {
    contactName: importerContactName,
    company: shipperToCompany,
    country: shipperToCountry,
    isAddressDifferent: importerIsAddressDifferent,
    details: importerAddressDetails,
  } = orderData?.importer || {};

  const {
    deliveryTerms,
    insuranceValue,
    insuranceCurrency,
  } = orderData?.general || {};

  const roundDifferenceValue = roundDifference || 0;

  const {
    totalQuantity,
    totalVolume,
    totalWeight,
  } = useInvoiceCalculations(orderData, services, roundDifferenceValue);

  /**  Extended shipping details string: >>> */
  const deliveryTermsItem = deliveryTerms
    ? deliveryTerms.split(' ')[0]
    : '';
  const deliveryTermsStr = deliveryTermsItem
    ? `Delivery terms - ${deliveryTermsItem}. `
    : '';

  const transportInsurance = insuranceValue || undefined;
  const transportInsuranceStr = transportInsurance
    ? `Transport insurance - ${CurrencyToSymbolEnum[insuranceCurrency || 'CHF']}${transportInsurance.toFixed(2)}, `
    : '';

  // Export/Import permit: free; obligation 654654 25.06.24; obligation 654654 24.06.24
  const permit = new Set<string>();

  orderData?.goods?.goods?.forEach(({ permit: permitType, permitNumber, permitDate }) => {
    if (permitType === 'free') {
      permit.add('free');
    } else {
      permit.add(`obligation ${permitNumber} ${dayjs(permitDate).format('DD.MM.YY')}`);
    }
  });

  const permitStr = [...permit]?.length ? ` export/import permit - ${[...permit].join('; ')}. ` : '';

  // Additional services: temperature logger; real time monitoring; special temperature mode
  const optionalServicesSet = new Set<string>();

  orderData?.goods?.goods?.forEach(({ temperatureLogger, realTimeMonitoring, specialTemperatureMode }) => {
    if (temperatureLogger) optionalServicesSet.add('Temperature logger');
    if (realTimeMonitoring) optionalServicesSet.add('Real time monitoring');
    if (specialTemperatureMode) optionalServicesSet.add('Special temperature mode');
  });

  const optionalServices = [...optionalServicesSet].join(', ');
  const optionalServicesStr = optionalServices
    ? `Additional services: ${optionalServices}.`
    : '';

  const shipperContact = `${
    shipperIsAddressDifferent ? shipperAddressDetails?.company : shipperFromCompany
  }, ${shipperFromCountry}, ${
    shipperIsAddressDifferent ? shipperAddressDetails?.contactName : contactName
  } - ${
    importerIsAddressDifferent ? importerAddressDetails?.company : shipperToCompany
  }, ${shipperToCountry}, ${importerContactName}. `;
  /**  Extended shipping details string: <<< */

  return (
    <>
      <div className={styles.column}>
        <strong>Shipping details</strong>
        {orderData?.shipper ? (
          <span>
            {/* {`Shipper contact ${contactName || ''}. From
                ${shipperFromCompany || ''},
                ${shipperFromCountry} - ${shipperToCompany},
                ${shipperToCountry}.
                Consignee contact ${importerContactName}. `} */}
            {shipperContact}
            <br />
            <span>
              {`
              ${deliveryTermsStr}
              ${transportInsuranceStr}
              ${permitStr}
              ${optionalServicesStr}
              `}
            </span>
          </span>
        ) : null}
      </div>
      <div className={styles.column}>
        <strong>Product</strong>
        <span>
          {totalQuantity ? `${totalQuantity}x,` : ''}
          {totalWeight ? ` ${totalWeight}kg,` : ''}
          {totalVolume ? ` ${Number(totalVolume)?.toFixed(4).replace(/\.?0+$/, '')}m³, ` : ''}
          {orderData?.goods?.goods ? (
            [...new Set(orderData.goods?.goods
              ?.map((item) => item.name || '')
              .filter(Boolean))].join(', ')
          ) : null}
          {/* {orderData?.goods?.goods?.some((item) => item.dangerousGood?.unNumber) && (
            ` / ${[...new Set(orderData.goods?.goods
              ?.map((item) => (item.dangerousGood?.unNumber ? `UN${item.dangerousGood.unNumber}` : ''))
              .filter(Boolean))].join(', ')}`
          )} */}
          {extractUnNumbersFromRes(orderData?.goods?.goods)}
        </span>
      </div>
    </>
  );
}

function InvoiceTotal({ invoice, orderData, simplified }: DetailsContainerProps) {
  const {
    currency,
    dueDate,
    services,
    roundDifference,
  } = invoice || {};

  const roundDifferenceValue = roundDifference || 0;

  const {
    amountDue,
  } = useInvoiceCalculations(orderData, services, roundDifferenceValue);

  return (
    <div className={styles.invoiceHeader}>
      <h2 style={{ margin: '20px 0' }}>
        Total to pay
        {' '}
        {amountDue?.toFixed(2)}
        {' '}
        {currency || 'CHF'}
        {!simplified ? (
          <>
            {' due date'}
            {dueDate ? (
              ` ${dayjs(dueDate).format('D MMMM YYYY')}`
            ) : null}
          </>
        ) : null}
      </h2>
    </div>
  );
}

function Table({ invoice, orderData }: DetailsContainerProps) {
  const {
    currency,
    services,
    roundDifference,
  } = invoice || {};

  const roundDifferenceValue = roundDifference || 0;

  const {
    totalWithVAT,
    subtotal,
    totalVAT,
    amountDue,
  } = useInvoiceCalculations(orderData, services, roundDifferenceValue);

  return (
    <div>
      <div className={clsx(styles.rowItem, styles.borderBottomBlack)}>
        {([
          { label: 'Service', span: 10 },
          { label: 'Qty.', span: 2, style: { alignItems: 'center' } },
          { label: 'Unit price', span: 3, style: { alignItems: 'end' } },
          { label: 'VAT', span: 3, style: { alignItems: 'end' } },
          { label: `Total price ${currency || 'CHF'}`, span: 5, style: { alignItems: 'end' } },
        ] as { label: string, span: number, style?: React.CSSProperties }[]).map(({ label, ...rest }) => (
          <Col key={label} className={styles.tableCol} {...rest}>
            <strong className={styles.tableRow}>{label}</strong>
          </Col>
        ))}
      </div>
      {(services || [])
        .sort(sortServicesByPriority)
        .map((service, index) => {
          if (service.value === 0) return null; // && service.tax === 0

          return (
          // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={clsx(styles.rowItem, styles.borderBottom)}>
              {([
                { label: service.name, span: 10 },
                { label: service.quantity, span: 2, style: { alignItems: 'center' } },
                { label: service.value?.toFixed(2), span: 3, style: { alignItems: 'end' } },
                {
                  label: Math.round((service.tax / 100) * service.value * service.quantity * 100) / 100,
                  span: 3,
                  style: { alignItems: 'end' },
                },
                {
                  label: (Math.round(((service.value * service.quantity) + ((service.tax / 100) * service.value * service.quantity)) * 100) / 100)?.toFixed(2),
                  span: 5,
                  style: { alignItems: 'end' },
                },
              ] as { label: string | number, span: number, style?: React.CSSProperties }[]).map(({ label, ...rest }) => (
                <Col key={crypto.randomUUID()} className={styles.tableCol} {...rest}>
                  <span className={styles.tableRow}>{label}</span>
                </Col>
              ))}
            </div>
          );
        })}
      <div style={{ maxWidth: '55%', minWidth: '320px', marginLeft: 'auto' }}>
        {[
          { label: 'Subtotal', value: subtotal?.toFixed(2) },
          { label: 'Total excluding VAT', value: totalWithVAT?.toFixed(2) },
          { label: 'VAT', value: totalVAT?.toFixed(2) },
          { label: 'Rounding difference', value: roundDifferenceValue?.toFixed(2) },
          { label: `Amount due ${currency || 'CHF'}`, value: amountDue?.toFixed(2), style: { fontWeight: '600' } },
        ].map(({ label, value, style }, index, arr) => (
          <Col
            key={label}
            span={24}
            className={clsx(styles.rowItem, { [styles.borderBottom]: index !== arr.length - 1 })}
          >
            <Col span={16}>
              <span className={styles.tableRow} style={style}>
                {label}
              </span>
            </Col>
            <Col span={8} className={styles.tableCol} style={{ alignItems: 'end' }}>
              <span className={styles.tableRow} style={style}>
                {value}
              </span>
            </Col>
          </Col>
        ))}
      </div>
    </div>
  );
}

function InvoiceDetailsContainer({ invoice, orderData, simplified }: DetailsContainerProps) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <ShippingDetails invoice={invoice} orderData={orderData} simplified={simplified} />
      <InvoiceTotal invoice={invoice} orderData={orderData} simplified={simplified} />
      <Table invoice={invoice} orderData={orderData} simplified={simplified} />
    </div>
  );
}

InvoiceDetailsContainer.Table = Table;
InvoiceDetailsContainer.Total = InvoiceTotal;
InvoiceDetailsContainer.ShippingDetails = ShippingDetails;

export default InvoiceDetailsContainer;
