import React, { useEffect, useMemo, useState } from 'react';
import {
  App, Button, Checkbox, Form, Modal, Select,
} from 'antd';
import { useParams } from 'react-router-dom';
import { ButtonProps } from 'antd/es/button/button';
import { useOrderAcceptUpdate } from '../../../../../hooks/api/order';
import { useMessageError, useMessageSuccess } from '../../../../../hooks/common';
import { OrderStatus } from '../../Adapter/enums';
import { useOrderContext } from '../../View/context';
import { isRoleEnough } from '../../../../../enums/user';
import { useAuth } from '../../../../../store/auth';
import { EmailsSettings, useOrderEmailsPutData } from '../../../../../hooks/api/orderEmails';
import { emailRegex, initialStateEmails } from '../../Form/EmailsTab';

interface UpdateEmailSettingsProps {
  okText?: React.ReactNode;
  loading?: boolean;
  buttonProps?: ButtonProps;
  buttonText?: React.ReactNode;
}

function AcceptAndUpdateEmailSettingsButton({
  okText = 'Accept',
  loading,
  buttonProps = {},
  buttonText = 'Accept',
}: UpdateEmailSettingsProps) {
  const { id: paramsOrderId = '' } = useParams();
  const { message } = App.useApp();
  const { user } = useAuth();
  const { order, clientOrderData } = useOrderContext();
  const orderEmailsPutData = useOrderEmailsPutData(paramsOrderId);
  const orderAcceptUpdate = useOrderAcceptUpdate(paramsOrderId);
  const isUserAdmin = isRoleEnough(user?.role, 'admin');

  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const handleOpen = (val: boolean) => {
    setOpen(val);
  };

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };

  useMessageError([orderEmailsPutData, orderAcceptUpdate]);
  useMessageSuccess([orderAcceptUpdate], 'Accept order successfully');

  const shipperEmailWatch = clientOrderData?.shipper?.email;
  const consigneeEmailWatch = clientOrderData?.importer?.email;

  const checkboxesRecipients = useMemo(() => ([
    { name: 'toCompany', title: 'Client', value: clientOrderData?.company?.email || '' },
    { name: 'toShipper', title: 'Shipper', value: shipperEmailWatch || '' },
    { name: 'toConsignee', title: 'Consignee', value: consigneeEmailWatch || '' },
  ]), [clientOrderData, shipperEmailWatch, consigneeEmailWatch]);

  /** Initial values handling */
  const [initialValues, setInitialValues] = useState<EmailsSettings<string[]>>(initialStateEmails);
  const prepareInitialValues = (emailSettings: EmailsSettings): EmailsSettings<string[]> => {
    const newValues = emailSettings || initialStateEmails;

    return ({
      ...newValues,
      additionalList: emailSettings?.additionalList ? emailSettings?.additionalList?.split(',') : [],
      customSettings: {
        ...newValues?.customSettings,
        // If there no custom settings attachments yet, take they from normal list.
        attachments: emailSettings?.customSettings?.attachments?.length
          ? emailSettings?.customSettings?.attachments : emailSettings.attachments || [],
        additionalList: emailSettings?.customSettings?.additionalList
          ? emailSettings?.customSettings?.additionalList?.split(',') : [],
      },
    });
  };

  useEffect(() => {
    if (clientOrderData?.emailSettings) {
      setInitialValues(prepareInitialValues(clientOrderData?.emailSettings));
    }
  }, [clientOrderData]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const handleOk = async () => {
    const values: EmailsSettings<string[]> = form.getFieldsValue();

    const newValues: EmailsSettings = {
      ...clientOrderData?.emailSettings,
      // @ts-ignore all good here, we just reuse values from api response
      customSettings: clientOrderData?.emailSettings.customSettings || initialStateEmails.customSettings,
      ...values,
      additionalList: values?.additionalList?.join(',') || '',
    };

    await orderEmailsPutData.fetch(newValues);
  };

  useEffect(() => {
    if (orderEmailsPutData.response?.status === 204) {
      orderEmailsPutData.clearResponse();
      orderEmailsPutData.clearError();

      orderAcceptUpdate.fetch().then((res) => {
        if (res?.id) {
          order.fetch();
          handleClose();
        }
      });
    }
  }, [orderEmailsPutData.response]);

  if (isUserAdmin && clientOrderData && clientOrderData.company && [
    // OrderStatus.DRAFT,
    OrderStatus.PENDING,
  ]
    .includes(clientOrderData?.status)) {
    return (
      (
        <>
          <Button
            type="primary"
            loading={orderEmailsPutData.loading || orderAcceptUpdate.loading}
            onClick={(e) => {
              e.preventDefault();
              handleOpen(true);
              // orderAcceptUpdate.fetch();
            }}
            {...buttonProps}
          >
            {buttonText}
          </Button>
          <Modal
            title="Email Recipients"
            open={open}
            onCancel={handleClose}
            onOk={form.submit}
            okText={okText}
            okButtonProps={{
              loading: loading || orderEmailsPutData.loading || orderAcceptUpdate.loading,
            }}
            width={500}
            destroyOnClose
            centered
          >
            <Form
              layout="vertical"
              form={form}
              onFinish={handleOk}
              initialValues={initialValues}
              // onFieldsChange={() => { setTrigger((prevState) => prevState + 1); }}
              className="no-padding-form-item"
            >
              {/* <h3 className={styles.emailsTitle}>Email Recipients</h3> */}
              {checkboxesRecipients.map((item) => (
                <Form.Item
                  name={[item.name]}
                  key={item.name}
                  valuePropName="checked"
                  style={{ marginBottom: '0' }}
                >
                  <Checkbox>
                    {`${item.title}: ${item.value}`}
                  </Checkbox>
                </Form.Item>
              ))}

              <div style={{ margin: '8px 0' }}>
                Additional recipients
                <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}> (optional)</span>
              </div>
              <Form.Item
                name={['additionalList']}
                style={{ marginBottom: '5px' }}
              >
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  showSearch={false}
                  suffixIcon={null}
                  tokenSeparators={[',']}
                  onSelect={(value) => {
                    if (!emailRegex.test(value)) {
                      message.error('The item you entered is not a valid email address. Please enter a valid email.');

                      // Remove the invalid entry if needed:
                      const currentValues = form.getFieldValue(['additionalList']) || [];

                      form.setFieldValue(
                        ['additionalList'],
                        currentValues.filter((item: string) => item !== value),
                      );
                    }
                  }}
                />
              </Form.Item>
              <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>Add emails in list.</span>
            </Form>
          </Modal>
        </>
      )
    );
  }

  return null;
}

export default AcceptAndUpdateEmailSettingsButton;
