import React, { useEffect, useMemo, useState } from 'react';

import { App, Form, Tabs } from 'antd';
import clsx from 'clsx';
import { FormProps } from 'antd/es/form/Form';
import { useParams } from 'react-router-dom';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { FormName, FormNames, useOrderContextForm } from './context';
import { customizeRequiredMark } from '../../../Common/Form/Input/common';
import { useAuth, UserRole } from '../../../../store/auth';
import Loading from '../../../Common/Loading';

import OrderType from './OrderType';
import General from './General';
import Shipper from './Shipper';
import Products from './Products';
import Documents from './Documents';
import Delivery from './Delivery';
import Overview from './Overview';
import Paperwork from './Paperwork';
import TrackingTab from './TrackingTab';
import Emails from './EmailsTab';

import { useOrderContext } from '../View/context';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { disabledToEditOrderStatuses, disabledToEditUserOrderStatuses, OrderStatus } from '../Adapter/enums';
import { findMainInvoice } from './Paperwork/Forms/ModalInvoice';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import { isRoleEnough } from '../../../../enums/user';
import { DeliveryServiceType } from '../../../../hooks/api/tracking';

import commonStyles from '../../../Common/Form/index.module.scss';
import styles from './index.module.scss';

export interface FormWrapperProps extends FormProps, FormName {
  children: React.ReactNode;
  disabled?: boolean;
}

export function FormWrapper({
  formName, children, disabled = false, layout, onFieldsChange, ...props
}: FormWrapperProps) {
  const { forms: { [formName]: form }, triggerValidationAllForm } = useOrderContextForm();
  const [trigger, setTrigger] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      triggerValidationAllForm();
    }, 500);

    return () => clearTimeout(timeout);
  }, [trigger]);

  return (
    <Form
      name={formName}
      className={clsx(commonStyles.form, commonStyles.miniTopPadding, styles.order, {
        [styles.vertical]: layout === 'vertical',
      })}
      layout={layout}
      // @ts-ignore - unknown error
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      autoComplete="off"
      requiredMark={customizeRequiredMark}
      onFieldsChange={(changedFields, allFields) => {
        if (onFieldsChange) { onFieldsChange(changedFields, allFields); }
        setTrigger((prevState) => prevState + 1);
      }}
      disabled={disabled}
      {...props}
    >
      {children}
    </Form>
  );
}

/** Used for 'Next step' button to validate current form by tab name. */
export enum tabsFormsEnum {
  'Order type' = 'orderType',
  'General data' = 'general',
  'Shipper' = 'shipper',
  'Consignee' = 'importer',
  'Products' = 'goods',
  'Packages' = 'packages',
  'Documents' = 'documents',
  'Delivery' = 'delivery',
}

export const orderTabs = [
  {
    forceRender: true,
    key: 'Order type',
    label: 'Order type',
    children: <OrderType formName="orderType" />,
    // roles: ['user'],
  },
  {
    forceRender: true,
    key: 'General data',
    label: 'General data',
    children: <General formName="general" />,
    roles: ['admin', 'root'],
  },
  {
    forceRender: true,
    key: 'Shipper',
    label: 'Shipper',
    children: <Shipper
      formName="shipper"
      titleAdditional="Pickup request"
      switchName={"The Pickup address should differ from the Shipper's address"}
    />,
  },
  {
    forceRender: true,
    key: 'Consignee',
    label: 'Consignee',
    children: <Shipper
      formName="importer"
      titleAdditional="Delivery request"
      switchName={"The Delivery address should differ from the Importer's address"}
    />,
  },
  {
    forceRender: true,
    key: 'Products',
    label: 'Products',
    children: <Products formName="goods" />,
  },
  {
    forceRender: true,
    key: 'Documents',
    label: 'Documents',
    children: <Documents formName="documents" />,
  },
  {
    forceRender: true,
    key: 'Delivery',
    label: 'Delivery',
    children: <Delivery formName="delivery" />,
  },
  {
    // forceRender: true,
    key: 'Overview',
    label: 'Overview',
    children: <Overview />,
    roles: ['user'],
    destroyInactiveTabPane: true,
  },
  {
    forceRender: true,
    key: 'Paperwork',
    label: 'Paperwork',
    children: <Paperwork />,
    roles: ['admin', 'root'],
  },
  {
    // forceRender: true,
    key: 'Emails',
    label: 'Emails',
    children: <Emails />,
    roles: ['admin', 'root'],
  },
  {
    forceRender: false,
    key: 'Tracking',
    label: 'Tracking',
    children: <TrackingTab />,
    roles: ['admin', 'root'],
  },
];

export const isOrderTabsDisabled = (status: OrderStatus | undefined, userRole?: UserRole): boolean => {
  let inProgressStatuses = disabledToEditOrderStatuses;

  if (userRole === 'user') {
    inProgressStatuses = disabledToEditUserOrderStatuses;
  }

  return !!(inProgressStatuses)?.includes(status || OrderStatus.DRAFT);
};

const alwaysActiveTabs = new Set(['Documents', 'Delivery', 'Overview', 'Paperwork', 'Emails', 'Tracking']);

export default function OrderForm(): React.ReactNode | null {
  const { id } = useParams();
  const { user } = useAuth();
  const { message, notification } = App.useApp();
  const [searchParams, setSearchParams, params] = useSearchParams();
  const {
    forms, setInitialState, orderSave, orderUpdate, orderCreate,
    isEmailsChanged, setIsEmailsChanged,
    deliveryForm,
  } = useOrderContextForm();
  const { order, clientOrderData } = useOrderContext();

  const deliveryServiceWatch: DeliveryServiceType = Form.useWatch(['deliveryService'], deliveryForm);

  useEffect(() => {
    if (!order.error && !order.loading && order.data && clientOrderData) {
      forms.orderType?.setFieldsValue(clientOrderData.orderType);
      setInitialState('orderType', clientOrderData.orderType);

      forms.general?.setFieldsValue(clientOrderData.general);
      setInitialState('general', clientOrderData.general);

      forms.shipper?.setFieldsValue(clientOrderData.shipper);
      setInitialState('shipper', clientOrderData.shipper);

      forms.importer?.setFieldsValue(clientOrderData.importer);
      setInitialState('importer', clientOrderData.importer);

      forms.goods?.setFieldsValue(clientOrderData.goods);
      setInitialState('goods', clientOrderData.goods);

      forms.packages?.setFieldsValue(clientOrderData.packages);
      setInitialState('packages', clientOrderData.packages);

      forms.documents?.setFieldsValue(clientOrderData.documents);
      setInitialState('documents', clientOrderData.documents);

      forms.delivery?.setFieldsValue(clientOrderData.delivery);
      setInitialState('delivery', clientOrderData.delivery);
    }
  }, [clientOrderData, order.data]);

  /** If order has status Processing and further then show only delivery and documents tab. (Admin can see all tabs) */
  useEffect(() => {
    if (clientOrderData && isOrderTabsDisabled(clientOrderData.status, user?.role)
      && !alwaysActiveTabs.has(params.tab as string)) {
      // TODO check this now
      setSearchParams({ tab: 'Delivery' }, { replace: true });
    }
  }, [clientOrderData]);

  /** Handling tabs state update.  */
  const filteredTabs = useMemo(() => orderTabs.filter((tab) => (
    !tab.roles || (tab.roles || []).includes(user?.role || '')
  )), [orderTabs, user]);

  const updatedTabs = useMemo(() => (
    filteredTabs.map((tab) => ({
      ...tab,
      /** If user is admin, then all tabs are active, but when he goes to one of disabled tabs - show info modal */
      disabled: isRoleEnough(user?.role, 'admin') || alwaysActiveTabs.has(tab.key)
      // For admin disable only 'tracking' tab in case if delivery service is 'documents'
        ? (deliveryServiceWatch === 'document' && tab.key === 'Tracking')
        : (isOrderTabsDisabled(clientOrderData?.status, user?.role)),
    }))
  ), [filteredTabs, clientOrderData, searchParams, forms, deliveryServiceWatch]);

  // TODO remove if keep unused
  /* const updatedTabs = useMemo(() => {
    console.log('re-render updated tabs:');
    // Get the current tab key from search params, fallback to the first tab if missing
    const currentTabKey = searchParams.get('tab') || filteredTabs[0]?.key;
    const currentFormName = tabsFormsEnum[currentTabKey as keyof typeof tabsFormsEnum] as FormNames | undefined;

    // Check if there's a form for the current tab and get its instance
    const currentForm = currentFormName && forms[currentFormName];

    // Determine whether the current form has validation errors

    console.log('currentForm:', currentForm);
    console.log('currentFormName:', currentFormName);
    console.log('currentForm.getFieldsError():', currentForm?.getFieldsError());
    console.log('is validation errors:', hasValidationErrors(currentForm));
    // eslint-disable-next-line no-nested-ternary
    const isDisabled = (tabKey: string) => (isRoleEnough(user?.role, 'admin') ? (
      alwaysActiveTabs.has(tabKey) ? false : (isOrderTabsDisabled(clientOrderData?.status))
    ) : (
      hasValidationErrors(currentForm)
    ));

    return (
      filteredTabs.map((tab) => ({
        ...tab,
        disabled: isDisabled(tab.key),
        // disabled: alwaysActiveTabs.has(tab.key) ? false : (isOrderTabsDisabled(clientOrderData?.status)
        // || hasValidationErrors(currentForm)),
      }))
    );
  }, [filteredTabs, clientOrderData, searchParams, forms, tabsRenderTrigger]); */

  /** On tabs update if there no current tab in search params - set it */
  /*  useEffect(() => {
    const currTab = searchParams.get('tab');

    if (!currTab) {
      setSearchParams({ tab: filteredTabs?.[0].key || 'Order type' });
    }
  }, [filteredTabs]); */

  /** If current form have errors - dont let user move */
  /* const handleTabChange = async (key: string) => {
    // Get the current form based on the tab key
    const currentFormName = tabsFormsEnum[params.tab as keyof typeof tabsFormsEnum] as FormNames;
    const currentForm = forms[currentFormName];

    if (currentForm) {
      try {
        await currentForm.validateFields();
        setSearchParams({ tab: key });
      } catch {
        message.error('Please fix all errors before moving to the next tab.');
      }
    } else {
      // If no form associated, proceed to the next tab
      setSearchParams({ tab: key });
    }
  }; */
  const { open, contextHolder } = useSimpleModal();
  /** Handles tabs change taking in count user role, direction, and is form valid. */
  const handleTabChange = async (key: string) => {
    // Get the current tab index and next tab index
    const currentTabKey = searchParams.get('tab') || (user?.role === 'user' ? 'Order type' : 'General data');
    const currentIndex = filteredTabs.findIndex((tab) => tab.key === currentTabKey);
    const nextIndex = filteredTabs.findIndex((tab) => tab.key === key);

    const callback = async () => {
      /** Get the form corresponding to the current tab */
      if (nextIndex > currentIndex && user?.role === 'user') {
        /** Loop through all forms between the current and the target tab */
        for (let i = currentIndex; i < nextIndex; i++) {
          const formKey = filteredTabs[i]?.key; // Get the tab key
          const formName = tabsFormsEnum[formKey as keyof typeof tabsFormsEnum] as FormNames;
          const form = forms[formName];

          if (form) {
            try {
              await form.validateFields();
              if (formName === 'goods' && forms?.packages) { await forms.packages?.validateFields(); }
            } catch {
              message.error(`Please fix all errors in the "${filteredTabs[i].label}" tab before proceeding.`);

              return; /** Stop the loop and prevent tab change */
            }
          }
        }

        /** If all validations pass, allow tab change */
        setSearchParams({ tab: key });
      } else {
        /** Allow moving to the previous tab for 'users' and allow any move for 'admins' */
        if (isOrderTabsDisabled(clientOrderData?.status, user?.role) && !alwaysActiveTabs.has(key)) {
          // const timeoutId =
          setTimeout(() => notification.warning(
            {
              key: 'notification',
              message: (
                <div>
                  Order already sent to the Delivery service. Changes in
                  {' '}
                  <b>{key}</b>
                  {' '}
                  section could result in errors
                </div>
              ),
              duration: 30,

            },
          ), 100);
        }
        setSearchParams({ tab: key });
      }
    };

    /** Additional logic on tab change */
    if (activeKey === 'Emails' && isEmailsChanged) {
      open({
        icon: <ExclamationCircleFilled />,
        title: 'Unsaved changes',
        content: (
          <span>
            Are you sure you want to leave this tab?
            <br />
            Changes you made will not be saved.
          </span>
        ),
        cancelText: 'Cancel',
        okText: 'Leave tab',
        onOk: () => {
          callback();
          setIsEmailsChanged(false);
        },
      });
    } else {
      await callback();
    }
  };

  // const activeKey: string = (params.tab as string) || (user?.role === 'user' ? 'Order type' : 'General data');
  const activeKey: string = (params.tab as string) || 'Order type';

  useEffect(() => {
    /** If user comes to products tab and order still not saved - save it */
    if (activeKey === 'Products' && !id) {
      orderSave(undefined, undefined, { isSimplifiedValidation: true });
    }

    /** When we come to 'paperwork' or to 'Emails' we need to save order */
    if ((activeKey === 'Paperwork' || activeKey === 'Emails') && id && clientOrderData
      // If tab - paperwork or emails and edit NOT disabled for order - save when coming to one of these tabs
      && !isOrderTabsDisabled(clientOrderData?.status, user?.role)
    ) {
      const mainInvoice = findMainInvoice(clientOrderData.invoices || []);
      const isUpdateInvoiceFlow = ['draft'].includes(mainInvoice?.status);

      orderSave(undefined, undefined, {
        isSimplifiedValidation: true,
        ...(isUpdateInvoiceFlow ? { query: '?updateInvoice=yes' } : {}),
      });
    }
  }, [activeKey, id]);

  return (
    <div
      className={clsx(commonStyles.form, commonStyles.miniTopPadding, styles.order, 'content-container')}
    >
      <Tabs
        key="orderTabs"
        defaultActiveKey={activeKey}
        activeKey={activeKey}
        items={updatedTabs}
        // onChange={(key) => setSearchParams({ tab: key })}
        onChange={handleTabChange}
      />

      {order.loading || orderUpdate.loading || orderCreate.loading ? <Loading absolute withBg /> : null}
      {contextHolder}
    </div>
  );
}
