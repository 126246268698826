import { useMemo } from 'react';
import { ClientData } from '../../../Orders/Adapter';
import { ServiceDetails } from '../../../../../hooks/api/invoices';

export const getTotalQuantity = (orderData?: ClientData | null) => {
  if (orderData?.packages?.packages?.length) {
    return orderData.packages.packages.reduce((sum, pack) => sum + (pack?.quantity || 0), 0);
  }

  return orderData?.goods?.goods?.reduce((sum, pack) => sum + (pack?.quantity || 0), 0) || 0;
};
export const getTotalVolume = (orderData?: ClientData | null) => {
  const volume = orderData?.packages?.packages?.length
    ? orderData.packages.packages.reduce(
      (sum, pack) => sum
        + ((pack?.height || 0) / 100)
        * ((pack?.width || 0) / 100)
        * ((pack?.length || 0) / 100),
      0,
    )
    : orderData?.goods?.goods?.reduce(
      (sum, pack) => sum
      + ((pack?.height || 0) / 100)
      * ((pack?.width || 0) / 100)
      * ((pack?.length || 0) / 100)
      * (pack?.quantity || 0),
      0,
    ) || 0;

  return Math.round(volume * 1000) / 1000;
};

// export const getTotalVolume = (orderData?: ClientData | null) => {
//   const volume = orderData?.packages?.packages?.length
//     ? orderData.packages.packages?.reduce(
//       (sum, pack) => sum
//         + (pack.height / 100)
//         * (pack.width / 100)
//         * (pack.length / 100),
//       0,
//     )
//     : orderData?.goods?.goods?.reduce(
//       (sum, pack) => sum
//       + (pack.height / 100)
//       * (pack.width / 100)
//       * (pack.length / 100)
//       * pack.quantity,
//       0,
//     ) || 0;

//   return Math.round(volume * 1000) / 1000; // Round to 3 decimal places
// };
export const getTotalWeight = (orderData?: ClientData | null) => {
  const weight = orderData?.packages?.packages?.length
    ? orderData.packages.packages.reduce(
      (sum, pack) => sum + (pack?.weight || 0),
      0,
    )
    : orderData?.goods?.goods?.reduce(
      (sum, pack) => sum + (pack?.gross || 0) * (pack?.quantity || 0),
      0,
    ) || 0;

  return Math.round(weight * 1000) / 1000;
};

// export const getTotalWeight = (orderData?: ClientData | null) => {
//   const weight = orderData?.packages?.packages?.length
//     ? orderData?.packages?.packages?.reduce(
//       (sum, pack) => sum + pack.weight,
//       0,
//     )
//     : orderData?.goods?.goods?.reduce(
//       (sum, pack) => sum + pack.gross * pack.quantity,
//       0,
//     ) || 0;

//   return Math.round(weight * 1000) / 1000; // Round to 3 decimal places
// };

export const getTotalGrossWeight = (orderData?: ClientData | null) => {
  const gross = orderData?.packages?.packages?.length
    ? orderData.packages.packages.reduce(
      (sum, pack) => sum + (pack?.weight || 0),
      0,
    )
    : orderData?.goods?.goods?.reduce(
      (sum, pack) => sum + ((pack?.gross || 0) * (pack?.quantity || 0)),
      0,
    ) || 0;

  return Math.round(gross * 1000) / 1000;
};

// export const getTotalGrossWeight = (orderData?: ClientData | null) => {
//   const gross = orderData?.packages?.packages?.length
//     ? orderData?.packages?.packages
//       .reduce((sum, pack) => sum + pack.weight, 0)
//     : orderData?.goods?.goods
//       ?.reduce((sum, pack) => sum + (pack.gross * pack.quantity), 0) || 0;
//   // {Number.parseFloat((currentList || []).reduce((accumulator, value) => accumulator
//   //   + (value?.innerPackaging
//   //       ? ((value?.gross || 0) * (value?.quantity || 1))
//   //       : (value?.gross || value?.net || 0) * (value?.quantity || 1)
//   //   ), 0).toFixed(3))}

//   return Math.round(gross * 1000) / 1000;
// };

export default function useInvoiceCalculations(
  orderData: ClientData | null | undefined,
  services: ServiceDetails[] | undefined, // Invoice['services']
  roundDifference: number | undefined,
) {
  const totalQuantity = useMemo(() => getTotalQuantity(orderData), [orderData]);
  const totalVolume = useMemo(() => getTotalVolume(orderData), [orderData]);
  const totalWeight = useMemo(() => getTotalWeight(orderData), [orderData]);
  const totalGross = useMemo(() => getTotalGrossWeight(orderData), [orderData]);

  const totalWithVAT = useMemo(() => Number(
    services?.reduce(
      (total, service) => {
        const serviceTotal = service.value * service.quantity;
        const serviceTax = (serviceTotal * service.tax) / 100;

        /** Round service total and handle the minimum tax value case (0.01) */
        const roundedServiceTotal = Math.round(serviceTotal * 100) / 100;
        const roundedServiceTax = service.tax && serviceTotal > 0
          ? Math.max(Math.round(serviceTax * 100) / 100, 0.01)
          : 0;

        return Math.round((total + roundedServiceTotal + roundedServiceTax) * 100) / 100;
      },
      0,
    ) || 0,
  ), [services]);

  const subtotal = useMemo(() => Number(
    services?.reduce(
      (total, service) => total + Math.round(service.value * service.quantity * 100) / 100,
      0,
    ),
  ), [services]);

  const totalVAT = useMemo(() => Number(
    services?.reduce(
      (total, service) => {
        const serviceTotal = service.value * service.quantity;
        const serviceTax = (serviceTotal * service.tax) / 100;

        /** Round the tax to 2 decimal places, with 0.01 min threshold if 'tax' not 0% */
        const roundedServiceTax = service.tax && serviceTotal > 0
          ? Math.max(Math.round(serviceTax * 100) / 100, 0.01)
          : 0;

        /** Accumulate the total, rounding the result to 2 decimal places */
        return Math.round((total + roundedServiceTax) * 100) / 100;
      },
      0,
    ) || 0,
  ), [services]);

  const amountDue = totalWithVAT + (roundDifference || 0); // round difference comes with - by default, so we +

  return {
    totalQuantity,
    totalVolume,
    totalWeight,
    totalGross,
    totalWithVAT,
    subtotal,
    totalVAT,
    amountDue,
  };
}
