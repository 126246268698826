import React from 'react';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import OrderSelect, { OrderSelectProps } from '../../Common/Select';
import { useDeliveryTermsGetAll } from '../../../../../../hooks/api/order';
import { FormNames } from '../../context';

function SelectDeliveryTerms({
  fetch,
  selectProps = {},
  rest = {},
  ...props
}: OrderSelectProps & { prefix?: FormNames }) {
  const dualUseGet = useDeliveryTermsGetAll();
  const form = useFormInstance();

  return (
    <OrderSelect
      fetch={dualUseGet}
      rest={rest}
      // selectProps={selectProps}
      selectProps={{
        allowClear: false,
        onChange: (value: string[]) => {
          form.setFieldValue([props.name as string], value[value.length - 1]); // props.prefix,
        },
        mode: 'tags',
        ...selectProps,
      }}
      {...props}
    />
  );
}

export default SelectDeliveryTerms;
